import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import PrivacyPolicyHero from "../components/PrivacyPolicyHero";
import PrivacyPolicyContent from "../components/PrivacyPolicyContent";

const PrivacyPolicy = () => {
  return (
    <>
      <NavBar />
      <PrivacyPolicyHero />
      <PrivacyPolicyContent />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
