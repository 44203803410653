import React from "react";
import config from "../utils/config";

const Aboutpage1 = () => {
  return (
    <>
      {/* ================== BannerOne Start ==================*/}
      {/* <div
        className="banner-area bg-relative banner-area-1 pd-bottom-100 bg-cover"
        style={{
          backgroundImage: `url(${`${config.baseUrl}/assets/img/banner/image.png`})`,
        }}
      >
        <div
          className="banner-area-home-page  banner-area-1 pd-bottom-100 bg-cover"
          style={{
            backgroundImage: `url(${
              config.isProd
                ? config.baseUrl
                : `${config.baseUrl}/assets/img/banner/gray.png`
            })`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="about-content">
                  <h2>About Us</h2>
                  <p
                    data-aos="fade-right"
                    data-aos-delay="250"
                    data-aos-duration="1500"
                    style={{
                      color: "white",
                      fontweight: "lighter",
                      marginTop: "20px",
                      textAlign: "center",
                      padding: "0 5%",
                    }}
                  >
                    We're your strategic partner in harnessing the power of
                    technology to drive innovation, efficiency, and growth. With
                    a passion for creating exceptional solutions, we're
                    dedicated to helping businesses of all sizes navigate the
                    ever-evolving digital landscape.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-8 offset-xl-2 offset-lg-1">
                <div
                  className="banner-thumb mt-5 mt-lg-0 ms-4 ms-lg-0"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                ></div>
              </div>
            </div>
          </div> */}
      .
      {/* <div className="about">
        <div className="row2">
          <div className="col-lg-6 align-self-center">
            <div className="about-content">
              <p
                // style={{
                //   color: "black",
                //   fontSize: "70px",
                //   fontWeight: "bold",
                // }}
              >
                Join our Fuzionest Fuzion!
              </p>
              <h4>Where crafting dreams become reality</h4>
            </div> */}

      <div className="abt-content">
        <p style={{ fontSize: "60px", lineHeight: 1.2 }} data-aos="fade-down">Empowering Ideas, Building the Future Together!</p>
        {/* <p data-aos="fade-up" data-aos-delay="200">
          Where crafting dreams become reality
        </p> */}

      </div>
      {/* </div> */}

      {/* <img
        src={`${config.baseUrl}/assets/img/banner/fuzion.png`}
        alt="fuzionImage"
        className="fuzion-image"
      /> */}

      <div className="steveImageContainer">
        <div className="steveImageContainer2">
          <div>
            <img
              className="imageDisplay imageDisplayVisible"
              src={`${config.baseUrl}/assets/img/Aboutpage/steveMainFrame.png`}
            />
          </div>

        </div>
      </div>


      {/* </div>
      </div> */}
      {/* ================== BannerOne End ==================*/}
    </>
  );
};

export default Aboutpage1;
