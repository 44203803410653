import styles from '../Product/RealTimeTracking.module.scss';

const RealTimeTracking = () => {
  return (
    <section className={styles.realTimeSection}>
      <div className={styles.topTagline}>
        <img src="/assets/img/products/painflower.png" alt="Decorative icon" className={styles.taglineIcon} />
        <p className={styles.taglineText}>Real-Time <span >Ride</span> Tracking, Maintenance Alerts, and Driver Performance Monitoring – All in One Place</p>
      </div>

      <div className={styles.topContent}>
        <img src="/assets/img/products/women_art.png" alt="Woman Illustration" className={styles.womanImage} />
        <p className={styles.description}>
          <strong> <span >RideX</span></strong> is a cutting-edge <span >Ride</span> management solution designed to streamline operations,<br></br> reduce costs, and enhance productivity.
        </p>
      </div>
    </section>
  );
};

export default RealTimeTracking;
