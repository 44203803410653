import styles from './YellowHighlight.module.scss';

const YellowHighlight = ({title}) => {
  return (
    <section className={styles.realTimeTrackingSection}>
      <div className={styles.contentWrapper}>
        <h2>
            {title}
        </h2>
      </div>
    </section>
  );
};

export default YellowHighlight;
