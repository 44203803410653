import React from "react";
import "./Uber.css";
import { Link } from "react-router-dom";
import config from "../utils/config";

const Uber = () => {
  return (
    <div className="container ">
      <div className="row">
        <div className="col-12">
          <h4
            className="serviceheading1"
            style={{ marginTop: "-3%", fontWeight: "900" }}
          >
            Bringing Uber-like App Solution To All Industries
          </h4>
        </div>
      </div>

      <div className="row  " style={{ padding: "0 15%" }}>
        <div className="col-12 col-md-6" style={{ border: "none" }}>
          <div className="card service-imgtext" style={{ border: "none" }}>
            <img
              // src="assets/img/Uber/1.png"
              src={`${config.baseUrl}/assets/img/Uber/1.png`}
              alt="Food App"
            />

            <h2 className="card-title1">Food</h2>
            <p className="card-content">
              Launch your Food delivery business and start delivering to
              customers doorsteps.
            </p>
            <button className="submitbutton" type="submit">
              <Link to="/contact">Book A Demo</Link>
            </button>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="card service-imgtext" style={{ border: "none" }}>
            <img
              // src="assets/img/Uber/2.png"
              src={`${config.baseUrl}/assets/img/Uber/2.png`}
              alt="Grocerys"
            />

            <h2 className="card-title1">Grocery</h2>
            <p className="card-content">
              Aggregate local vendors and start selling groceries on-demand in
              the neighborhood.
            </p>
            <button className="submitbutton" type="submit">
              <Link to="/contact">Book A Demo</Link>
            </button>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="card service-imgtext" style={{ border: "none" }}>
            <img
              // src="assets/img/Uber/3.png"
              src={`${config.baseUrl}/assets/img/Uber/3.png`}
              alt="Beauty"
            />

            <h2 className="card-title1">Beauty</h2>
            <p className="card-content">
              Onboard Salons and beauticians in the area and facilitate online
              appointment booking for customers.
            </p>
            <button className="submitbutton" type="submit">
              <Link to="/contact">Book A Demo</Link>
            </button>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="card service-imgtext" style={{ border: "none" }}>
            <img
              // src="assets/img/Uber/4.png"
              src={`${config.baseUrl}/assets/img/Uber/4.png`}
              alt="Pharmacy"
            />

            <h2 className="card-title1">Pharmacy</h2>
            <p className="card-content">
              Allow customers to order medicines online and start delivering to
              customers doorsteps.
            </p>
            <button className="submitbutton" type="submit">
              <Link to="/contact">Book A Demo</Link>
            </button>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="card service-imgtext" style={{ border: "none" }}>
            <img
              // src="assets/img/Uber/5.png"
              src={`${config.baseUrl}/assets/img/Uber/5.png`}
              alt="Delivery"
            />

            <h2 className="card-title1">Pick up and delivery</h2>
            <p className="card-content">
              Launch your own pick-up and delivery service like Postmates and
              earn by charging delivery fees.
            </p>
            <button className="submitbutton" type="submit">
              <Link to="/contact">Book A Demo</Link>
            </button>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="card service-imgtext" style={{ border: "none" }}>
            <img
              // src="assets/img/Uber/6.png"
              src={`${config.baseUrl}/assets/img/Uber/6.png`}
              alt="Services"
            />

            <h2 className="card-title1">Home Services</h2>
            <p className="card-content">
              Launch your own platform to help customers book handyman services
              on demand.
            </p>
            <button className="submitbutton" type="submit">
              <Link to="/contact">Book A Demo</Link>
            </button>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="card service-imgtext" style={{ border: "none" }}>
            <img
              // src="assets/img/Uber/7.png"
              src={`${config.baseUrl}/assets/img/Uber/7.png`}
              alt="Laundry"
            />

            <h2 className="card-title1">Laundry</h2>
            <p className="card-content">
              Setup your online laundry/dry cleaning pick up and delivery
              service with our on-demand suite.
            </p>
            <button className="submitbutton" type="submit">
              <Link to="/contact">Book A Demo</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uber;
