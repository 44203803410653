import React from "react";

const WebsiteTermsOfUseContent = () => {
  return (
    <div>
      <h2 className="title-rsl">Fuzionest's Terms of Service:</h2>
      <div className="rsl-pagetwo">
        <h3 className="rsl-heading">1. Acceptance of Terms</h3>
        <p className="rsl-para">
          By accessing and using <a href="/">www.fuzionest.com</a>, you agree to comply with these terms.
        </p>

        <h3 className="rsl-heading">2. Use of Site</h3>
        <p className="rsl-para">
          You agree to use the site for lawful purposes and not to engage in any activity that disrupts the site.
        </p>

        <h3 className="rsl-heading">3. Intellectual Property</h3>
        <p className="rsl-para">
          All content on this site, including text, graphics, logos, and images, is owned by Fuzionest and protected by copyright and other intellectual property laws.
        </p>

        <h3 className="rsl-heading">4. User Content</h3>
        <p className="rsl-para">
          By submitting content to our site, you grant Fuzionest a non-exclusive, royalty-free, perpetual license to use, modify, and distribute your content.
        </p>

        <h3 className="rsl-heading">5. Limitation of Liability</h3>
        <p className="rsl-para">
          Fuzionest is not liable for any direct, indirect, incidental, or consequential damages arising from the use of the site.
        </p>

        <h3 className="rsl-heading">6. Indemnification</h3>
        <p className="rsl-para">
          You agree to indemnify and hold Fuzionest harmless from any claims arising out of your use of the site.
        </p>

        <h3 className="rsl-heading">7. Changes to Terms</h3>
        <p className="rsl-para">
          We may update these terms at any time. Continued use of the site constitutes acceptance of the new terms.
        </p>

        <h3 className="rsl-heading">8. Contact Us</h3>
        <p className="rsl-para">
          For any questions, please contact us at &nbsp;
          <a style={{ color: "#1967D2" }} href="mailto:contact@fuzionest.com">
            contact@fuzionest.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default WebsiteTermsOfUseContent;
