import styles from '../Product/ActionalbleSection.module.scss';

const ActionalbleSection = () => {
  return (
    <section className={styles.realTimeVisibility}>
      <div className={styles.curvedCard}>
        <div className={styles.textContent}>
          <h2>
            <span className={styles.highlight}>Control Y</span>our Costs 
          </h2>
          <p>
          Keep track of expenses in real-time, ensuring you stay within budget and maximize profits.
          </p>
        </div>

        <div className={styles.mapContent}>
          <img src="/assets/img/products/Frame 2.png" alt="Fleet map and control interface" className={styles.mapImage} />
        </div>
      </div>
    </section>
  );
};

export default ActionalbleSection;
