import React from "react";

const ImageGrid = () => {
  return (
    <div className="grid-3">
      {/* <h4
        className="grid-head-work"
        style={{ fontWeight: "700", fontFamily: "Inter, sans-serif" }}
      >
        Work Culture
      </h4>
      <p className="gridhead-work" style={{ color: "black", maxWidth: "100%" }}>
        Discover a work culture that's more than just a routine – it's a vibrant
        ecosystem of innovation and collaboration. At Fuzionest, we foster an
        environment where creativity thrives, challenges are embraced, and every
        voice is valued. Our culture celebrates diversity, encourages continuous
        learning, and empowers each team member to push boundaries and explore
        new horizons. With a perfect blend of camaraderie and autonomy, we're
        not just shaping code – we're shaping a community that nurtures growth
        and brings extraordinary ideas to life. Welcome to a workplace where
        passion meets technology, and together, we redefine possibilities.
      </p> */}

      <div className="core-values">
        <h2 style={{ width: "100%" }}>Our Core Values</h2>

        {/*  <h3>The Standard by which we run our company</h3>
        <p>
          We have a well- established agile process for full-cycle software
          development. An agile company, we welcome changes. If you wish to
          bring your process, we’ll adjust to them quickly.
        </p> */}

      </div>

      <div class="container">
        <div class="card1">
          <div class="corner-text">Integrity and Transparency </div>
          <div class="card-content">
            <p class="card-number">01</p>

            <p class="card-no1">01</p>
            <p class="card-description">We are committed to honesty and openness in all client interactions, ensuring clear communication about our capabilities, timelines, and challenges. This helps us build trust and foster long-term relationships.</p>
          </div>
        </div>
        <div class="card2">
          <div class="corner-text">We Encourage personal growth</div>
          <div class="card-content">
            <p class="card-number">02</p>
            <p class="card-no1">02</p>
            <p class="card-description">Behind every great company are great people.
              We invite employees to become the best version of themselves,
              both personally and professionally.
            </p>
          </div>
        </div>
        <div class="card3">
          <div class="corner-text">Security and Privacy</div>
          <div class="card-content">
            <p class="card-number">03</p>
            <p class="card-no1">03</p>
            <p class="card-description">We prioritize data security and client privacy, adhering to the highest standards of protection and compliance to safeguard your information. This commitment is especially important in an era of increasing concerns about data breaches.
            </p>
          </div>
        </div>
        <div class="card4">
          <div class="corner-text">Reliability and Accountability</div>
          <div class="card-content">
            <p class="card-number">04</p>
            <p class="card-no1">04</p>
            <p class="card-description">We consistently deliver on our promises and take full responsibility for the outcomes of our projects. We are dedicated to meeting deadlines, maintaining system uptime, and providing dependable support.
            </p>
          </div>
        </div>
      </div>


    </div>
  );
};

export default ImageGrid;
