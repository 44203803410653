import React from "react";
import "./Aboutpage5.css"; // Import your CSS file for styling
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import config from "../utils/config";

// const teamMembers = [
//   // {
//   //   id: 1,
//   //   name: "KALAISELVAN S",
//   //   role: "Senior Associate",
//   //   imageUrl: "assets/img/about/team/Kalai.jpg",
//   // },
//   {
//     id: 2,
//     name: "KISHORE P",
//     role: "Senior Associate",
//     imageUrl: `${config.baseUrl}/assets/img/about/team/Kishore.jpg`,
//   },
//   {
//     id: 3,
//     name: "MANIKANDAN S",
//     role: "Senior Associate",
//     imageUrl: `${config.baseUrl}/assets/img/about/team/Mani.jpg`,
//   },
//   {
//     id: 4,
//     name: "MANOJ KUMAR S",
//     role: "Senior Associate",
//     imageUrl: `${config.baseUrl}/assets/img/about/team/Manoj.jpg`,
//   },
//   // {
//   //   id: 5,
//   //   name: "ARTHI A",
//   //   role: "Associate",
//   //   imageUrl: "assets/img/about/team/Arthi.jpg",
//   // },
//   // {
//   //   id: 6,
//   //   name: "CINDRELLA S",
//   //   role: "Associate",
//   //   imageUrl: "assets/img/about/team/Cindrella.jpg",
//   // },
//   {
//     id: 13,
//     name: "SABARI M",
//     role: "Associate",
//     imageUrl: `${config.baseUrl}/assets/img/about/team/Sabari.jpg`,
//   },
//   {
//     id: 8,
//     name: "JACKSON S",
//     role: "Associate",
//     imageUrl: `${config.baseUrl}/assets/img/about/team/Jackson.jpg`,
//   },
//   {
//     id: 7,
//     name: "DEEPAK SAMBATH",
//     role: "Associate",
//     imageUrl: `${config.baseUrl}/assets/img/about/team/Deepak.jpg`,
//   },
//   // {
//   //   id: 9,
//   //   name: "NAVANEETH KRISHNAN S",
//   //   role: "UI/UX designer",
//   //   imageUrl: "assets/img/about/team/Navaneeth.jpg",
//   // },
//   {
//     id: 10,
//     name: "PAVITHRA S",
//     role: "Business Analyst",
//     imageUrl: `${config.baseUrl}/assets/img/about/team/Pavithra.jpg`,
//   },
//   {
//     id: 11,
//     name: "PRIYANKA VELUSAMY",
//     role: "Associate",
//     imageUrl: `${config.baseUrl}/assets/img/about/team/Priyanka.jpg`,
//   },
//   {
//     id: 12,
//     name: "RAVI KUMAR P",
//     role: "Associate",
//     imageUrl: `${config.baseUrl}/assets/img/about/team/Ravi.jpg`,
//   },

//   {
//     id: 14,
//     name: "SUBASH CHANDRA BOSE",
//     role: "Associate",
//     imageUrl: `${config.baseUrl}/assets/img/about/team/Subash.jpg`,
//   },
//   {
//     id: 15,
//     name: "VARSHINI B",
//     role: "Associate",
//     imageUrl: `${config.baseUrl}/assets/img/about/team/Varshini.jpg`,
//   },
//   {
//     id: 16,
//     name: "VIJAYAKUMAR M",
//     role: "Cloud Engineer",
//     imageUrl: `${config.baseUrl}/assets/img/about/team/Vijay.jpg`,
//   },
// ];

const teamMembers = [
  {
    id: 1,
    imageUrl: `${config.baseUrl}/assets/img/Aboutpage/group1.jpeg`,
  },
  {
    id: 2,
    imageUrl: `${config.baseUrl}/assets/img/Aboutpage/group2.jpeg`,
  },
  {
    id: 3,
    imageUrl: `${config.baseUrl}/assets/img/Aboutpage/group1.jpeg`,
  },
  {
    id: 4,
    imageUrl: `${config.baseUrl}/assets/img/Aboutpage/group2.jpeg`,
  }
  
];

function Aboutpage5() {
  return (
    <div className="container2">
    <div className="image-container-group">
    {/* {teamMembers.map((member) => (
        <div key={member.id} className="image-container-group-item">
          <img
            className="image-container-group5"
            src={member.imageUrl}
            alt="group"
          />
        </div>
      ))} */}


    {teamMembers.map((member) => (
      <div key={member.id} className="image-container-group-item">
        <img
          className="image-container-group5"
          src={member.imageUrl}
          alt="group"
        />
      </div>
    ))}
    {/* Duplicate the images to create a seamless loop */}
    {teamMembers.map((member) => (
      <div key={member.id + '_clone'} className="image-container-group-item">
        <img
          className="image-container-group5"
          src={member.imageUrl}
          alt="group"
        />
      </div>
    ))}
  
    </div>
      {/* <div className="blue-box" style={{ position: "relative" }}>
        <img
          className="GroupPic-aboutpages"
          src="assets/img/about/team/gppic.jpg"
          alt="Fuzionest Team"
        />
        <div className="bluebox-aboutpage">
          <h5>Join Our Team</h5>
          <p>
            Want to do work that matters? We’re changing the way how digital
            transformation is approached. Join us today to become part of
            something greater than yourself.
          </p>
          <Link
            className="btn btn-border-base23"
            to="/careers"
            style={{
              borderWidth: "2px",
              alignItems: "center",
              fontSize: "15px",
              padding: "0 20px",
            }}
          >
            View Openings <FaAngleRight size={25} className="rounded-icon-1" />
          </Link>
        </div>
      </div> */}
    </div>
  );
}

export default Aboutpage5;
