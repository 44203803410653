import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Stopthink from "../components/Stopthink";
import Map from "../components/Map";
import ServiceAreaOne from "../components/ServiceAreaOne";
import Uber from "../components/Uber";
import { Helmet } from "react-helmet";
import StartegicFuzionHero from "../components/StartegicFuzionHero";
import StartegicFuzionContent from "../components/StartegicFuzionContent";
import Faq from "../components/Faq";
import FooterFive from "../components/footerFive";
import faqData from "../utils/strategicfuzionFaq.json"

const StrategicFuzion = () => {
  const browserTabTitle = "Strategic Fuzion";
  //   const seoTitle =
  //     "Customized Technology Solutions for Your Unique Needs | Fuzionest";
  //   const seoDescription =
  //     "Explore Fuzionest's tailored technology solutions designed to meet your specific business requirements. From bespoke consulting to personalized project development, leverage our expertise to achieve optimal efficiency and innovation. Discover how we can customize solutions to drive your success.";
  //   const seoKeywords =
  //     "Tailored technology solutions, bespoke consulting, personalized project development, custom tech solutions, business efficiency, tech innovation, customizations, clone apps, customized it services, tech consulting services, business process automation, custom software development, on demand booking applications, vehicle tracking solutions, fleet tracking management.";

  return (
    <>
      <Helmet>
        <title>{browserTabTitle}</title>
        {/* <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} /> */}
        {/* Schema Markup */}
        {/* <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "${browserTabTitle}",
              "url": "https://www.fuzionest.com/tailored-solutions",
              "description": "${seoDescription}",
              "mainEntity": {
                "@type": "Organization",
                "name": "Fuzionest",
                "url": "https://www.fuzionest.com",
                "logo": "https://www.fuzionest.com/assets/img/landing/FuzionestLogo.svg",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "contactType": "Customer Service",
                  "telephone": "+91 84387 62921",
                  "email": "contact@fuzionest.com",
                  "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Coimbatore",
                    "addressRegion": "Tamil Nadu",
                    "postalCode": "641014",
                    "addressCountry": "India",
                    "streetAddress": "Tidel Park, Module No.12 Ground floor Elcot SEZ Coimbatore IT Park Road"
                  }
                }
              }
            }
          `}
        </script> */}
      </Helmet>

      <NavBar />
      <StartegicFuzionHero />
      <StartegicFuzionContent />
      {/* <!-- FAQ --> */}
     <Faq faqData={faqData} />
     <FooterFive />
    </>
  );
};

export default StrategicFuzion;
