import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import CloudBlog from "../components/cloudBlog";
import CloudBlogContent from "../components/CloudBlogContent";

const BlogPage = () => {
  return (
    <>
      <NavBar />
      <CloudBlog />
      <CloudBlogContent />
      {/* <Rslpagethree /> */}
      <Footer />
    </>
  );
};

export default BlogPage;
