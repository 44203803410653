import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the default CSS for the date picker
import styles from "./demo.module.scss"; // Import your custom SCSS styles as a module
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@radix-ui/react-tabs";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

const AppointmentScheduler = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedTime, setSelectedTime] = useState(null);
  const [details, setDetails] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    website: "",
  });
  const [activeTab, setActiveTab] = useState("dateTime");
  const [openDatePicker, setOpenDatePicker] = useState(true); // State to control date picker visibility

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleDetailChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    // Open date picker automatically on mount
    if (openDatePicker) {
      setOpenDatePicker(true);
    }
  }, [openDatePicker]);

  return (
    <section className={styles.appointmentContainer}>
      <div className="container mx-auto p-4">
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList>
            <TabsTrigger value="dateTime">Date & Time</TabsTrigger>
            <TabsTrigger value="details">Details</TabsTrigger>
            <TabsTrigger value="booked">Booked</TabsTrigger>
          </TabsList>

          <TabsContent value="dateTime">
            <DateTimeTabContent
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              onDateChange={handleDateChange}
              onTimeChange={handleTimeChange}
              openDatePicker={openDatePicker} // Pass open state
              setOpenDatePicker={setOpenDatePicker} // Pass set function
            />
          </TabsContent>

          <TabsContent value="details">
            <DetailsTabContent
              details={details}
              onDetailChange={handleDetailChange}
            />
          </TabsContent>

          <TabsContent value="booked">
            <BookedTabContent
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              details={details}
            />
          </TabsContent>
        </Tabs>
      </div>
    </section>
  );
};

const DateTimeTabContent = ({

  selectedDate,
  selectedTime,
  onDateChange,
  onTimeChange,
  openDatePicker,
  setOpenDatePicker,
}) => {
  const [tempSelectedDate, setTempSelectedDate] = useState(selectedDate); // Temporarily store the selected date for OK/Cancel logic

  const handleOkClick = () => {
    onDateChange(tempSelectedDate); // Update the actual selected date
    setOpenDatePicker(false); // Close the date picker
  };

  const handleCancelClick = () => {
    setTempSelectedDate(selectedDate); // Revert to previously selected date
    setOpenDatePicker(false); // Close the date picker
  };

  return (
    <div className={styles.schedulingContainer}>

      <div className={styles.datePickerContainer}>
        <h2 className="text-2xl font-bold">Demo with Expert</h2>
        <p>Select a date & time</p>
        <div className={styles["datepicker__input-container"]}>
          <DatePicker
            selected={selectedDate}
            onChange={onDateChange}
            onCalendarOpen={() => setOpenDatePicker(false)} 
            onCalendarClose={() => setOpenDatePicker(true)} 
            inline
            className={styles.customDatePicker}
            calendarClassName={styles.customCalendar} 
            style={{ width: "400px" }} 
          />

       
          <div className={styles.buttonContainer}>
            <button className={styles.cancelButton} onClick={handleCancelClick}>
              Cancel
            </button>
            <button className={styles.okButton} onClick={handleOkClick}>
              OK
            </button>
          </div>
        </div>
      </div>

      {/* Right Side - Time Slots */}
      <div className={styles.timeSlotsContainer}>
        <label className={styles.timeLabel}>Select a time</label>
        <div className={styles.timeButtons}>
          {[
            "10:00 AM",
            "11:00 AM",
            "12:00 PM",
            "01:00 PM",
            "02:00 PM",
            "03:00 PM",
            "04:00 PM",
            "05:00 PM",
          ].map((time) => (
            <button
              key={time}
              className={`${styles.timeButton} ${
                selectedTime === time ? styles.activeTimeButton : ""
              }`}
              onClick={() => onTimeChange(time)}
            >
              {time}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  phone: yup.string().matches(/(^\+\d{1,3}\s?\d{7,12}$|^\d{7,12}$)/, 'Phone number is not valid').required('Phone number is required'),
  password: yup.string()
    .matches(/(?=.*[a-z].*[a-z])/, 'Must have at least 2 lowercase characters')
    .matches(/(?=.*[A-Z].*[A-Z])/, 'Must have at least 2 uppercase characters')
    .matches(/(?=.*\d.*\d)/, 'Must have at least 2 numbers')
    .matches(/(?=.*[!@#$%^&*()_\-+=;:'",<.>/?].*[!@#$%^&*()_\-+=;:'",<.>/?])/, 'Must have at least 2 special characters')
    .required('Password is required'),
});




// Component for Details Tab
const DetailsTabContent = ({ details, onDetailChange }) => {

  const { control, handleSubmit, formState: { errors }, register, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  
  

  const [submitError, setSubmitError] = useState(''); 
  const onSubmit = async (data) => {
    try {
      const response = await fetch('http://192.168.1.22:8001/auth/v1/manualSignup?manul=true', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer YOUR_AUTH_TOKEN',
        },
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          phone: data.phone,
          
        }),
      });

      const jsonResponse = await response.json();

      if (jsonResponse.status === 1) {
        toast.success(jsonResponse.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.error('Signup failed: ' + jsonResponse.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('Error during signup:', error);
      toast.error('An error occurred. Please try again.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold">Add more details about you</h2>
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
            {submitError && <p className={styles.error}>{submitError}</p>}
            <div className={styles.formGroup}>
              <label>Name*</label>
              <input
                type="text"
                name="name"
                placeholder="e.g. John Smith"
                {...register('name')}
                className="form-control"
              />
              {errors.name && <p className={styles.error}>{errors.name.message}</p>}
            </div>

            <div className={styles.formGroup}>
              <label>Email ID*</label>
              <input
                type="email"
                name="email"
                placeholder="e.g. johnsmith@gmail.com"
                {...register('email')}
                className="form-control"
              />
              {errors.email && <p className={styles.error}>{errors.email.message}</p>}
            </div>

            <div className={styles.formGroup}>
              <label>Password*</label>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                {...register('password')}
                className="form-control"
              />
              {errors.password && <p className={styles.error}>{errors.password.message}</p>}
            </div>

            

            <button type="submit" className={styles.submitBtn}>
              Start Now
            </button>
          </form>
    </div>
  );
};

// Component for Booked Tab
const BookedTabContent = ({ selectedDate, selectedTime, details }) => {
  return (
    <div>
      <h2 className="text-2xl font-bold">Appointment Scheduled!</h2>
      <p>Your appointment is booked for:</p>
      <p>
        {selectedDate?.toLocaleDateString()} at {selectedTime}
      </p>
      <p>Details:</p>
      <ul>
        <li>Name: {details.name}</li>
        <li>Email: {details.email}</li>
        <li>Phone Number: {details.phoneNumber}</li>
        <li>Company Name: {details.companyName}</li>
      </ul>
    </div>
  );
};

export default AppointmentScheduler;
