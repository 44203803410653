import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../utils/config";

const SmartAutomationContent = () => {
  const genAiImgs = [
    "automate-task",
    "enhancing-decision-making",
    "user-interface",
    "seamless-integration",
    "analytics",
  ];

  const [activeIndexGenAi, setActiveIndexGenAi] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndexGenAi((prevIndex) => (prevIndex + 1) % genAiImgs.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div className="bg-cover pd-top-90">
        <div className="">
          <div className="d-flex justify-content-center position-relative">
            <div className="eclipse"></div>
            <div className="shadow-box1">
              <div className="dots">
                <img
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                  alt="handshake"
                />
              </div>
              <div className="decorate">
                <img
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-1.png`}
                  alt="handshake"
                />
              </div>
              <img
                src={`${config.baseUrl}/assets/img/smart-automation/smart-automation.png`}
                alt="handshake"
                style={{ borderRadius: "12px" }}
              />
            </div>
          </div>
        </div>

        <div className="container content-height">
          <div className="row g-2 pd-top-10 position-relative px-2">
            <div
              className="col-xl-5 col-lg-5 col-md-12 d-flex justify-content-center align-items-center order-md-first"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-content">
                <h2 style={{ fontWeight: "600" }}>
                  Understanding Smart Automation with GenAI
                </h2>
                <p className="text-justify">
                  Generative AI represents a revolutionary leap in technology,
                  capable of creating new data, solutions, and insights based on
                  existing information. Unlike traditional automation, which
                  relies on predefined rules, GenAI learns and evolves, making
                  it adept at handling complex tasks and decision-making
                  processes.
                </p>
              </div>
            </div>

            <div
              className="col-xl-7 col-lg-7 col-md-12 order-md-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0 d-flex justify-content-center justify-content-lg-end">
                <div className="shadow-box-2">
                  <div className="dots">
                    <img
                      // src="assets/img/strategic-fuzion/dots.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                      alt="handshake"
                    />
                  </div>
                  <img
                    className="main-img"
                    src="assets/img/strategic-fuzion/customizedStrategy.png"
                    alt="customized Strategy"
                  />
                  <div className="left-arrow">
                    <img
                      src="assets/img/strategic-fuzion/left-arrow.png"
                      alt="left-arrow"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="work order-md-last">
              <img src="assets/img/strategic-fuzion/work.png" alt="work" />
            </div>
          </div>
        </div>

        {/* Future Oriented */}

        <div className="section-content position-relative py-4 my-4">
          <img
            className="main-img"
            src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-group.png`}
            alt="Future Oriented"
            style={{ width: "100%" }}
          />
          <h2 className="float-content" style={{ fontWeight: "600" }}>
            How GenAI Boosts Workforce Efficiency
          </h2>
        </div>

        <div className="container">
          <div className="row g-2 pd-top-10 px-2">
            <div
              className="col-xl-6 col-lg-6 col-md-12 order-lg-last d-flex justify-content-end align-items-center"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0 d-flex justify-content-center justify-content-lg-end">
                <div className="shadow-box-2">
                  <div className="dots">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                      alt="handshake"
                    />
                  </div>
                  {/* <div className="star-1">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star.png`}
                      alt="handshake"
                    />
                  </div> */}
                  <div className="decorate-right">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-3.png`}
                      alt="left-arrow"
                    />
                  </div>
                  <img
                    className="main-img"
                    // src={`${config.baseUrl}/assets/img/smart-automation/${genAiImgs[activeIndexGenAi]}.png`}
                    src={`${config.baseUrl}/assets/img/smart-automation/automate-task.png`}
                    alt="Customized ERP"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-12 d-flex row g-2 justify-content-center align-items-center"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-content">
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                      alt="handshake"
                      width={150}
                      className={`${
                        0 === activeIndexGenAi ? "active-index" : "inactive"
                      }`}
                    />
                    <img
                      src={`${config.baseUrl}/assets/img/rapid-customization/line.png`}
                      alt="line"
                      height={60}
                      className={`${
                        0 === activeIndexGenAi ? "active-index" : "inactive"
                      }`}
                    />
                  </div>

                  <p
                    className={`text-justify ${
                      0 === activeIndexGenAi ? "active-index" : "inactive"
                    }`}
                  >
                    <b>Automating Repetitive Tasks:</b> One of the most
                    immediate benefits of GenAI is its ability to automate
                    repetitive and mundane tasks. From data entry to scheduling
                    and reporting, GenAI can handle these tasks with precision
                    and speed, freeing up your employees to focus on more
                    strategic and creative activities.
                  </p>
                </div>
              </div>
              <div className="section-content">
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                      alt="handshake"
                      width={150}
                      className={`${
                        1 === activeIndexGenAi ? "active-index" : "inactive"
                      }`}
                    />
                    <img
                      src={`${config.baseUrl}/assets/img/rapid-customization/line.png`}
                      alt="line"
                      height={60}
                      className={`${
                        1 === activeIndexGenAi ? "active-index" : "inactive"
                      }`}
                    />
                  </div>
                  <p
                    className={`text-justify ${
                      1 === activeIndexGenAi ? "active-index" : "inactive"
                    }`}
                  >
                    <b>Enhancing Decision-Making:</b>
                    GenAI excels at analyzing vast amounts of data and providing
                    actionable insights. This capability empowers your workforce
                    to make informed decisions quickly and accurately, whether
                    it's optimizing supply chain operations, forecasting market
                    trends, or enhancing customer service strategies.
                  </p>
                </div>
              </div>
              <div className="section-content">
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                      alt="handshake"
                      width={150}
                      className={`${
                        2 === activeIndexGenAi ? "active-index" : "inactive"
                      }`}
                    />
                    <img
                      src={`${config.baseUrl}/assets/img/rapid-customization/line.png`}
                      alt="line"
                      height={60}
                      className={`${
                        2 === activeIndexGenAi ? "active-index" : "inactive"
                      }`}
                    />
                  </div>
                  <p
                    className={`text-justify ${
                      2 === activeIndexGenAi ? "active-index" : "inactive"
                    }`}
                  >
                    <b>Personalizing Customer Interactions:</b> Today's
                    customers expect personalized experiences. GenAI can analyze
                    customer behavior and preferences to deliver tailored
                    interactions, improving customer satisfaction and loyalty.
                    This level of personalization can be applied across
                    marketing, sales, and support functions, enhancing overall
                    customer engagement.
                  </p>
                </div>
              </div>
              <div className="section-content">
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                      alt="handshake"
                      width={150}
                      className={`${
                        3 === activeIndexGenAi ? "active-index" : "inactive"
                      }`}
                    />
                    <img
                      src={`${config.baseUrl}/assets/img/rapid-customization/line.png`}
                      alt="line"
                      height={60}
                      className={`${
                        3 === activeIndexGenAi ? "active-index" : "inactive"
                      }`}
                    />
                  </div>
                  <p
                    className={`text-justify ${
                      3 === activeIndexGenAi ? "active-index" : "inactive"
                    }`}
                  >
                    <b>Streamlining Workflows:</b>By integrating GenAI into your
                    existing systems, you can streamline workflows and eliminate
                    bottlenecks. GenAI can intelligently route tasks, prioritize
                    actions, and ensure that resources are allocated
                    efficiently, leading to smoother and faster operations.
                  </p>
                </div>
              </div>
              <div className="section-content">
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-end">
                    <img
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                      alt="handshake"
                      width={150}
                      className={`${
                        4 === activeIndexGenAi ? "active-index" : "inactive"
                      }`}
                    />
                    <img
                      src={`${config.baseUrl}/assets/img/rapid-customization/line.png`}
                      alt="line"
                      height={60}
                      className={`${
                        4 === activeIndexGenAi ? "active-index" : "inactive"
                      }`}
                    />
                  </div>
                  <p
                    className={`text-justify ${
                      4 === activeIndexGenAi ? "active-index" : "inactive"
                    }`}
                  >
                    <b>Continuous Learning and Improvement:</b> GenAI systems
                    are designed to learn and improve over time. This means that
                    as your business grows and evolves, your GenAI-powered
                    automation solutions will adapt and become even more
                    effective, continuously driving efficiency and productivity
                    gains.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* All other features */}

        <div className="container">
          <div
            className="d-flex gap-3 justify-content-center align-items-center flex-flow-wrap"
            data-aos="fade-left"
            data-aos-delay="100"
            data-aos-duration="1500"
          >
            <div className="section-title-faq mb-0 mt-8 mt-lg-0 section-content">
              <h2 style={{ fontWeight: "600", paddingLeft: "160px" }}>
                Real-World Applications of GenAI in Business Processes
              </h2>
              <p style={{ paddingLeft: "160px" }}>
                At Fuzionest, we have successfully implemented GenAI solutions
                across various industries, helping our clients achieve
                remarkable improvements in workforce efficiency. Here are a few
                examples
              </p>
            </div>
            <div className="star-3">
              <img src="assets/img/strategic-fuzion/star.png" alt="handshake" />
            </div>
          </div>
          <div
            className="row g-4 pd-top-10 px-2"
            data-aos="fade-left"
            data-aos-delay="100"
            data-aos-duration="1500"
          >
            {/* <div className="section-title-faq mb-0 mt-4 mt-lg-0 d-flex justify-content-center justify-content-lg-end">
                <div className="shadow-box-2" style={{ maxWidth:"100%"}}>  
                
                  <img
                    className="main-img"
                    
                    src={`${config.baseUrl}/assets/img/smart-automation/intelligent-chat.png`}
                    alt="Customized ERP"
                  />
                  <p fontWeight="bold">Intelligent Chat Assistants:</p>
                  <p>
                   Our intelligent chat assistants leverage GenAI to provide real-time support and information to employees and customers. These assistants can handle a wide range of inquiries, from simple FAQs to complex troubleshooting, significantly reducing response times and improving service quality.
                  </p>
                 </div>
                 <div className="shadow-box-3">
                
                <img
                  className="main-img"
            
                  src={`${config.baseUrl}/assets/img/smart-automation/automate-task.png`}
                  alt="Customized ERP"
                />
                <p fontWeight="bold">Automated Data Analysis: </p>
                <p>
                We have developed GenAI solutions that automate data analysis processes,
                 providing businesses with instant insights into their operations.
                 This automation not only saves time but also ensures accuracy and consistency in data interpretation.
                </p>
               </div>
               <div className="shadow-box-4">
                
                <img
                  className="main-img"
                  // src={`${config.baseUrl}/assets/img/smart-automation/${genAiImgs[activeIndexGenAi]}.png`}
                  src={`${config.baseUrl}/assets/img/smart-automation/dynamic-resource.png`}
                  alt="Customized ERP"
                />
                <p fontWeight="bold">  Dynamic Resource Allocation:</p>
                <p>
                  Our GenAI-powered systems can dynamically allocate resources based on real-time demand and priorities.
                This ensures that your workforce is always focused on the most critical tasks, 
                optimizing productivity and efficiency.
                </p>
               </div>
              </div> */}
          </div>
        </div>

        <div className="container">
          <div className="row g-2 pd-top-10 px-2">
            <div
              className="col-xl-6 col-lg-6 col-md-12 order-lg-last d-flex justify-content-end align-items-center"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-lg-0 d-flex justify-content-center justify-content-lg-end">
                <div className="shadow-box-11">
                  <div className="shadow-box-12">
                    {/* <p fontWeight="bold">Automated Data Analysis: </p> */}
                    <p>
                      Automated Data Analysis:<br></br>
                      We have developed GenAI solutions that automate data
                      analysis processes, providing businesses with instant
                      insights into their operations. This automation not only
                      saves time but also ensures accuracy and consistency in
                      data interpretation.
                    </p>
                    <img
                      className="main-img"
                      src={`${config.baseUrl}/assets/img/smart-automation/automate-task.png`}
                      alt="Customized ERP"
                    />
                  </div>
                  <div className="shadow-box-13">
                    <p>
                      {" "}
                      Dynamic Resource Allocation:<br></br>
                      Our GenAI-powered systems can dynamically allocate
                      resources based on real-time demand and priorities. This
                      ensures that your workforce is always focused on the most
                      critical tasks, optimizing productivity and efficiency.
                    </p>
                    <img
                      className="main-img"
                      // src={`${config.baseUrl}/assets/img/smart-automation/${genAiImgs[activeIndexGenAi]}.png`}
                      src={`${config.baseUrl}/assets/img/smart-automation/dynamic-resource.png`}
                      alt="Customized ERP"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-12 d-flex row g-2 justify-content-center align-items-center"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="shadow-box-14" style={{ maxWidth: "100%" }}>
                <img
                  className="main-img"
                  src={`${config.baseUrl}/assets/img/smart-automation/intelligent-chat.png`}
                  alt="Customized ERP"
                />
                <p fontWeight="bold">Intelligent Chat Assistants:</p>
                <p>
                  Our intelligent chat assistants leverage GenAI to provide
                  real-time support and information to employees and customers.
                  These assistants can handle a wide range of inquiries, from
                  simple FAQs to complex troubleshooting, significantly reducing
                  response times and improving service quality.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="features-section">
          <div className="container">
            <div className="pd-top-10" style={{ paddingTop: "10%" }}>
              <h2 className="feature-content">
                Why Choose Fuzionest for GenAI Integration?
              </h2>
              <div className="row g-2 px-2">
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>
                        Expertise and Innovation
                      </h2>
                      <p className="text-justify">
                        With a team of seasoned experts and a commitment to
                        innovation, Fuzionest is at the forefront of GenAI
                        technology. We continuously explore new ways to leverage
                        GenAI to solve business challenges and enhance
                        efficiency.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>
                        Customized Solutions
                      </h2>
                      <p className="text-justify">
                        We understand that every business is unique. Our
                        approach is highly personalized, ensuring that the GenAI
                        solutions we develop are tailored to meet your specific
                        needs and objectives.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>End-to-End Support</h2>
                      <p className="text-justify">
                        From initial consultation to implementation and ongoing
                        support, we provide comprehensive services to ensure the
                        success of your GenAI integration. Our goal is to be a
                        trusted partner in your journey toward smart automation.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>Proven Success</h2>
                      <p className="text-justify">
                        Our track record speaks for itself. We have helped
                        numerous clients achieve significant improvements in
                        workforce efficiency and overall business performance
                        through the strategic implementation of GenAI.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            className="d-flex justify-content-center position-relative"
            style={{ paddingBottom: "100px" }}
          >
            <img
              className="main-img"
              src={`${config.baseUrl}/assets/img/strategic-fuzion/vector-bg.png`}
              alt="Vector-bg"
            />
            <p className="custom-float" style={{ fontWeight: "600" }}>
              <em>Wait! There is more!</em>
            </p>
            <div className="left-arrow-4">
              <img
                src={`${config.baseUrl}/assets/img/strategic-fuzion/left-arrow-4.png`}
                alt="left-arrow"
              />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="footer-content-2">
            <div className="position-relative">
              <h2 className="my-4">
                Transform Your Business
                <span style={{ color: "#357C83" }}>with Smart Automation</span>
              </h2>
              <div className="decorate-2">
                <img
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-4.png`}
                  alt="handshake"
                />
              </div>
              <div className="decorate-right-2">
                <img
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-5.png`}
                  alt="handshake"
                />
              </div>
            </div>
            <Link to="/contact">
              <button className="btn btn-base border-radius-0">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default SmartAutomationContent;
