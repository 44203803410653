import styles from '../Product/OtherFeatures.module.scss';
import config from "../../../utils/config";

const OtherFeatures = () => {
  return (
    <section className={styles.otherFeaturesSection}>
      <div className={styles.animatedMessage}>
        <img
          src={`${config.baseUrl}/assets/img/products/animMsg.png`}
          // src="/assets/img/products/animMsg.png"
          alt="Animated CEO Message"
          className={styles.messageImage}
        />
      </div>

      <div className={styles.featuresWrapper}>
        <h2>Other Features</h2>

        <div className={styles.featuresContainer}>
          {/* Feature 1 */}
          <div className={styles.featureCard}>
            <img
              src="/assets/img/products/Frame 1686554090.png"
              alt="Simplify Your Ride Bookings"
              className={styles.featureImage}
            />
            <div className={styles.featureText}>
              <h3>AI-Powered Automation</h3>
              <p>
                Manual data entry is a thing of the past. With FuzionBooks, you can scan invoices, bills and purchase orders and let AI automatically generate accounting entries. This eliminates errors and saves valuable time.
              </p>
              <button className={styles.viewMoreBtn}>View More</button>
            </div>
          </div>

          {/* Feature 2 */}
          <div className={styles.featureCard}>
            <img
              src="/assets/img/products/Frame3.png"
              alt="Agents for Your Regular Tasks"
              className={styles.featureImage}
            />
            <div className={styles.featureText}>
              <h3>Efficient Receivables & Payable  </h3>
              <p>
                Receivables: Creates professional, branded invoices, set up recurring payments and manage customer relationships seamlessly. With built-reminders, ensure timely payments from clients.Payables: Manage vendor payments, convert vendor invoices to bills, and track expenses effortlessly. Auto-scan receipts and categorize expenses for better insights.
              </p>
              <button className={styles.viewMoreBtn}>View More</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OtherFeatures;
