import React from "react";
import "./Footer.css";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import image from "./icon.png";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-row">
        <div className="footer-column">
          <h5 className="footer-heading">
            <a href="/service">On-Demand Talent</a>
          </h5>
          <p>
            <a href="/service">Resource Hiring</a>
          </p>
          <p>
            <a href="/service">Hire Android Developers</a>
          </p>
          <p>
            <a href="/service">Hire iOS Developers</a>
          </p>
          <p>
            <a href="/service">Hire Web Developers</a>
          </p>
          <p>
            <a href="/service">Hire Full Stack Developers</a>
          </p>
        </div>
        <div className="footer-column">
          <h5 className="footer-heading">
            <a href="/service">Cloud Consulting</a>
          </h5>

          <p>
            <a href="/service">Cloud Cost Optimisation</a>
          </p>
          <p>
            <a href="/service">Cloud Migration</a>
          </p>
          <p>
            <a href="/service">Cloud Development</a>
          </p>
          <p>
            <a href="/service">Hire AWS Developer</a>
          </p>
          {/* <p><a href="#">Salon Booking App Clone</a></p>    */}
        </div>

        <div className="footer-column">
          <h5 className="footer-heading">
            <a href="/tailored-solutions">Clone Apps</a>
          </h5>
          <p>
            <a href="/tailored-solutions">Food Delivery App Clone</a>
          </p>
          <p>
            <a href="/tailored-solutions">Supermarket App Clone</a>
          </p>
          <p>
            <a href="/tailored-solutions">Laundry Booking App Clone</a>
          </p>
          <p>
            <a href="/tailored-solutions">Pharmacy App Clone</a>
          </p>
          <p>
            <a href="/tailored-solutions">Salon Booking App Clone</a>
          </p>
        </div>
        <div className="footer-column">
          <h5 className="footer-heading">
            <a href="/technology">App Development</a>
          </h5>
          <p>
            <a href="/technology">Android App Development </a>
          </p>
          <p>
            <a href="/technology">iOS App Development</a>
          </p>
          <p>
            <a href="/technology">Flutter App Development</a>
          </p>
        </div>
        <div className="footer-column">
          <h5 className="footer-heading">
            <a href="/contact">Contact</a>
          </h5>
          <p>
            <a href="/about">About Us</a>
          </p>
          <p>
            <a href="/contact">Contact Us</a>
          </p>

          <p>
            <a href="/careers">Careers</a>
          </p>
        </div>
      </div>
      <div
        className="footer-row"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 0",
        }}
      >
        <div className="d-flex" style={{ width: "100%" }}>
          <p className="footerheading">Fuzionest</p>
          <p className="footerheading-para">Making Businesses Resilient</p>
        </div>
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ width: "100%" }}
        >
          <div className="icon-round">
            <div className="round-icon">
              <a href="https://www.facebook.com/profile.php?id=100088143221117&mibextid=LQQJ4d">
                <FaFacebookF />
              </a>
            </div>
            <div className="round-icon">
              <a href="https://www.instagram.com/fuzionest_limited/">
                <FaInstagram />
              </a>
            </div>
            <div className="round-icon">
              <a href="https://twitter.com/Fuzionestpvtltd">
                {/* <img
                  src={image}
                  alt="Twitter"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                /> */}
                <FaTwitter />
              </a>
            </div>
            <div className="round-icon">
              <a href="https://www.linkedin.com/company/fuzionest/mycompany/?viewAsMember=true">
                <FaLinkedinIn />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-row1">
        <p>
          <span> &copy; 2024 &nbsp; </span>
          <Link to="/">Fuzionest, Private Limited</Link>&nbsp;|&nbsp;&nbsp;
          <Link to="/privacy-policy">Privacy Policy</Link>&nbsp;|&nbsp;&nbsp;
          <Link to="/website-terms">Website Terms</Link>&nbsp;|&nbsp;&nbsp;
          <Link to="/website-Accessibility">Accessibility</Link>
          &nbsp;|&nbsp;&nbsp;
          <Link to="/cookies-policy">Cookies Policy</Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
