import React from "react";
import { Link } from "react-router-dom";
import config from "../utils/config";

const EliteMultiContentContent = () => {
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div className="bg-cover pd-top-90">
        <div className="">
          <div className="d-flex justify-content-center position-relative">
            <div className="eclipse"></div>
            <div className="shadow-box">
              <div className="dots">
                <img
                  // src="assets/img/strategic-fuzion/dots.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                  alt="handshake"
                />
              </div>
              <div className="decorate">
                <img
                  // src="assets/img/strategic-fuzion/decorate-1.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-1.png`}
                  alt="handshake"
                />
              </div>
              <div className="multi-tenancy-img">
                <img
                  className="multi-tenancy"
                  src={`${config.baseUrl}/assets/img/elite-multi/multi-tenancy.png`}
                  alt="multi-tenancy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row g-2 pd-top-10 px-2">
            <div
              className="col-xl-5 col-lg-5 col-md-12 order-lg-last order-md-first d-flex justify-content-center align-items-center"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="mb-0 mt-4 mt-lg-0 section-content">
                <h2 style={{ fontWeight: "600" }}>
                  Optimal Resource Allocation
                </h2>
                <p className="">
                  <b> Maximize Efficiency:</b> Experience dynamic resource
                  allocation that maximizes efficiency. Our systems ensure each
                  tenant receives the necessary resources without compromising
                  performance, adapting in real-time to meet demand.
                </p>
              </div>
            </div>
            <div
              className="col-xl-7 col-lg-7 col-md-12"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="pt-lg-3">
                <img
                  className="main-img"
                  // src="assets/img/elite-multi/optimal-resource.png"
                  src={`${config.baseUrl}/assets/img/elite-multi/optimal-resource.png`}
                  alt="optimal-resource"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container content-height">
          <div className="row g-2 pd-top-10 position-relative px-2">
            {/* <!-- content --> */}
            <div
              className="col-xl-4 col-lg-4 col-md-12 d-flex justify-content-center align-items-center order-md-first"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-content">
                <h2 style={{ fontWeight: "600" }}>Customized Strategies</h2>
                <p className="text-justify">
                  <b>Grow Without Limits:</b> Our advanced server orchestration
                  seamlessly scales to support more tenants and higher
                  workloads. Enjoy smooth performance regardless of your
                  business's growth and expansion.
                </p>
              </div>
            </div>

            {/* <!-- img --> */}
            <div
              className="col-xl-8 col-lg-8 col-md-12 order-md-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="d-flex justify-content-center justify-content-lg-end">
                <div className="shadow-box-2">
                  <div className="dots">
                    <img
                      // src="assets/img/strategic-fuzion/dots.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                      alt="dots"
                    />
                  </div>
                  <img
                    className="main-img"
                    // src="assets/img/elite-multi/scalabilitiy.png"
                    src={`${config.baseUrl}/assets/img/elite-multi/scalabilitiy.png`}
                    alt="scalabilitiy"
                  />
                  <div className="left-arrow">
                    <img
                      // src="assets/img/strategic-fuzion/left-arrow.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/left-arrow.png`}
                      alt="left-arrow"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="sass order-md-last">
              <img
                //  src="assets/img/elite-multi/sass.png"
                src={`${config.baseUrl}/assets/img/elite-multi/sass.png`}
                alt="sass"
              />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row pd-top-10 px-2">
            <div
              className="col-xl-5 col-lg-5 col-md-12 order-lg-last d-flex justify-content-center align-items-center position-relative"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0 section-content">
                <h2 style={{ fontWeight: "600" }}>High Availability</h2>
                <p>
                  <b>Uninterrupted Service: </b> Benefit from robust failover
                  mechanisms and load balancing. Our system ensures your
                  application remains responsive and available, even during
                  hardware failures or traffic spikes.
                </p>
              </div>
              <div className="star">
                <img
                  src="assets/img/strategic-fuzion/star.png"
                  alt="handshake"
                />
              </div>
            </div>
            <div
              className="col-xl-7 col-lg-7 col-md-12 d-md-flex justify-content-center justify-content-lg-start"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="shadow-box-2">
                <div className="dots">
                  <img
                    // src="assets/img/strategic-fuzion/dots.png"

                    src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                    alt="dots"
                  />
                </div>
                <div className="decorate">
                  <img
                    // src="assets/img/strategic-fuzion/decorate-2.png"
                    src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-2.png`}
                    alt="handshake"
                  />
                </div>
                <img
                  className="main-img"
                  // src="assets/img/elite-multi/highAvailabilty.png"
                  src={`${config.baseUrl}/assets/img/elite-multi/highAvailabilty.png`}
                  alt="data Driven"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row g-2 pd-top-10 px-2">
            {/* <!-- content --> */}
            <div
              className="col-xl-4 col-lg-4 col-md-12 d-flex justify-content-center align-items-center order-md-first"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-content">
                <h2 style={{ fontWeight: "600" }}>Simplified Management</h2>
                <p className="text-justify">
                  <b>Effortless Oversight:</b> Manage your server environment
                  with ease. Our centralized dashboards provide a comprehensive
                  view, allowing you to monitor performance, allocate resources,
                  and troubleshoot issues efficiently.
                </p>
              </div>
            </div>

            {/* <!-- img --> */}
            <div
              className="col-xl-8 col-lg-8 col-md-12 order-md-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0 d-flex justify-content-center justify-content-lg-end">
                <div className="shadow-box-2">
                  <div className="dots">
                    <img
                      // src="assets/img/strategic-fuzion/dots.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                      alt="handshake"
                    />
                  </div>
                  <div className="left-arrow-2">
                    <img
                      // src="assets/img/strategic-fuzion/left-arrow-2.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/left-arrow-2.png`}
                      alt="left-arrow"
                    />
                  </div>
                  <img
                    className="main-img"
                    // src="assets/img/elite-multi/simplifiedManagement.png"
                    src={`${config.baseUrl}/assets/img/elite-multi/simplifiedManagement.png`}
                    alt="Simplified Management"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Future Oriented */}

        <div className="section-content position-relative py-4 my-4">
          <img
            className="main-img"
            // src="assets/img/strategic-fuzion/decorate-group.png"
            src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-group.png`}
            alt="Future Oriented"
            style={{ width: "100%" }}
          />
          <h2 className="float-content" style={{ fontWeight: "600" }}>
            Isolation and Segmentation
          </h2>
        </div>

        <div className="container">
          <div className="row g-2 pd-top-10 px-2">
            <div
              className="col-xl-5 col-lg-6 col-md-12 order-lg-last d-flex justify-content-center position-relative"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0 section-content">
                <h2 style={{ fontWeight: "600" }}>Deep Industry Knowledge</h2>
                <p className="">
                  <b>Secure Data Integrity:</b> Ensure data integrity and
                  privacy with strict tenant isolation. Our system prevents
                  cross-tenant access, securing each tenant’s data and
                  operations separately.
                </p>
              </div>
              <div className="star-2">
                <img
                  // src="assets/img/strategic-fuzion/star-2.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                  alt="handshake"
                />
              </div>
              <div className="left-arrow-3">
                <img
                  // src="assets/img/strategic-fuzion/left-arrow-3.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/left-arrow-3.png`}
                  alt="left-arrow"
                />
              </div>
            </div>
            <div
              className="col-xl-7 col-lg-6 col-md-12 d-flex justify-content-center justify-content-lg-start"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="shadow-box-2">
                <div className="dots">
                  <img
                    //  src="assets/img/strategic-fuzion/dots.png"
                    src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                    alt="dots"
                  />
                </div>
                <img
                  className="main-img"
                  // src="assets/img/elite-multi/secureDataIntegrity.png"
                  src={`${config.baseUrl}/assets/img/elite-multi/secureDataIntegrity.png`}
                  alt="Secure Data Integrity"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row g-2 pd-top-10 px-2">
            <div
              className="col-xl-7 col-lg-6 col-md-12 order-lg-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0 d-flex justify-content-center justify-content-lg-end">
                <div className="shadow-box-2">
                  <div className="dots">
                    <img
                      // src="assets/img/strategic-fuzion/dots.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                      alt="handshake"
                    />
                  </div>
                  <div className="star-1">
                    <img
                      // src="assets/img/strategic-fuzion/star.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star.png`}
                      alt="handshake"
                    />
                  </div>
                  <div className="decorate-right">
                    <img
                      // src="assets/img/strategic-fuzion/decorate-3.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-3.png`}
                      alt="left-arrow"
                    />
                  </div>
                  <img
                    className="main-img"
                    // src="assets/img/strategic-fuzion/innovativeSolution.png"
                    src={`${config.baseUrl}/assets/img/strategic-fuzion/innovativeSolution.png`}
                    alt="Innovative Solution"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-xl-5 col-lg-6 col-md-12 d-flex row g-2 justify-content-center align-items-center"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-content">
                <h2 style={{ fontWeight: "600" }}>Advanced Encryption</h2>
                <p className="text-justify">
                  <b>Protect Sensitive Information: </b>
                  Keep your data secure with state-of-the-art encryption
                  technologies. Our robust encryption protects your information
                  both at rest and in transit from unauthorized access and cyber
                  threats.
                </p>
              </div>
              <div className="custom-border"></div>
              <div className="section-content">
                <h2 style={{ fontWeight: "600" }}>Continuous Monitoring</h2>
                <p className="text-justify">
                  <b>Proactive Threat Detection: </b> Stay ahead of potential
                  threats with continuous monitoring. Our system analyzes
                  patterns and behaviors in real time to identify and mitigate
                  risks before they impact your application.
                </p>
              </div>
              <div className="custom-border"></div>
              <div className="section-content">
                <h2 style={{ fontWeight: "600" }}>Compliance and Governance</h2>
                <p className="text-justify">
                  <b>Meet Industry Standards: </b> Easily comply with industry
                  standards and regulations. Our solutions include regular
                  audits and compliance checks, ensuring your data security and
                  privacy are always up to date.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            className="d-flex justify-content-center position-relative"
            style={{ paddingBottom: "100px" }}
          >
            <img
              className="main-img"
              // src="assets/img/strategic-fuzion/vector-bg.png"
              src={`${config.baseUrl}/assets/img/strategic-fuzion/vector-bg.png`}
              alt="Vector-bg"
            />
            <p className="custom-float" style={{ fontWeight: "600" }}>
              <em>Wait! There is more!</em>
            </p>
            <div className="left-arrow-4">
              <img
                // src="assets/img/strategic-fuzion/left-arrow-4.png"
                src={`${config.baseUrl}/assets/img/strategic-fuzion/left-arrow-4.png`}
                alt="left-arrow"
              />
            </div>
          </div>
        </div>

        {/* All other features */}

        <div className="features-section">
          <div className="container">
            <div
              className="pd-top-10"
              style={{ width: "100%", paddingTop: "10%" }}
            >
              <h2 className="feature-content">All other features</h2>
              <div className="row g-2 px-2">
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        // src="assets/img/strategic-fuzion/tiny-star.png"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>Access Control</h2>
                      <p className="text-justify">
                        <b>Enhanced Security Measures: </b> Secure your
                        application with granular access controls. Role-based
                        access control (RBAC) and multi-factor authentication
                        (MFA) ensure only authorized users can access specific
                        resources and data.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        // src="assets/img/strategic-fuzion/tiny-star.png"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>
                        High-Speed Processing
                      </h2>
                      <p className="text-justify">
                        <b>Improve Response Times: </b>
                        Optimize processing power to reduce latency and enhance
                        application response times. Our system ensures resources
                        are available when needed, providing a seamless user
                        experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        // src="assets/img/strategic-fuzion/tiny-star.png"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>
                        Customizable Performance Tiers
                      </h2>
                      <p className="text-justify">
                        <b>Tailor Resources to Your Needs:</b>
                        Choose from different performance tiers to match your
                        specific needs. High-priority applications receive the
                        necessary resources for optimal performance, ensuring
                        top-tier functionality.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        // src="assets/img/strategic-fuzion/tiny-star.png"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>
                        Continuous Optimization
                      </h2>
                      <p className="text-justify">
                        <b>Stay at Peak Performance: </b>
                        Benefit from continuous optimization with our machine
                        learning algorithms. Our system constantly analyzes
                        performance and makes real-time adjustments to enhance
                        efficiency and speed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="footer-content-2">
            <div className="position-relative">
              <h2 className="my-4">
                Ready to Elevate Your &nbsp;
                <span style={{ color: "#357C83" }}>
                  Multi-Talent Application?
                </span>
              </h2>
              <div className="decorate-2">
                <img
                  // src="assets/img/strategic-fuzion/decorate-4.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-4.png`}
                  alt="handshake"
                />
              </div>
              <div className="decorate-right-2">
                <img
                  // src="assets/img/strategic-fuzion/decorate-5.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-5.png`}
                  alt="handshake"
                />
              </div>
            </div>

            <Link to="/contact">
              <button className="btn btn-base border-radius-0">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default EliteMultiContentContent;
