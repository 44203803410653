import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import config from "../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/bootstrap.css";
import countryList from "../utils/country.json";
import axios from 'axios';
let contact_url = process.env.CONTACT_US_URL;


const Contactarea1 = () => {
  const initialFormData = {
    name: "",
    email: "",
    phone: "91+",
    company: "",
    message: "",
  };

  if (!contact_url) {
    contact_url = "https://api.fuzionest.com/contactus";
  }

  const heroVideoRef = useRef(null);
  const [formData, setFormData] = useState(initialFormData);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const inputRef = useRef(null);

  useEffect(() => {
    // Check if the URL contains #ContactDetails
    if (window.location.href.includes("#ContactDetails")) {
      // Set focus on the input field using the ref
      if (inputRef.current) {
        inputRef.current.focus();
        window.scrollBy(0, 750);
      }
    }
  }, []);

  // const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [activeTab, setActiveTab] = useState("business");
  const [selectedFile, setSelectedFile] = useState(null);

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().when("activeTab", {
      is: "business",
      then: (schema) => schema.required("Name is required"),
      otherwise: (schema) => schema.optional(),
    }),
    country: Yup.string().when("activeTab", {
      is: "business",
      then: (schema) => schema.required("Country is required"),
      otherwise: (schema) => schema.optional(),
    }),
    phone: Yup.string().when("activeTab", {
      is: "business",
      then: (schema) =>
        schema.required("Phone number is required")
          .matches(/^[0-9]+$/, "Phone number must contain only numbers") // Ensures numeric-only input
      ,
      otherwise: (schema) => schema.optional(),
    }),
    email: Yup.string()
      .email("Invalid email format")
      .when("activeTab", {
        is: "business",
        then: (schema) => schema.required("Email is required"),
        otherwise: (schema) => schema.optional(),
      }),
    details: Yup.string().when("activeTab", {
      is: "business",
      then: (schema) => schema.required("Details is required"),
      otherwise: (schema) => schema.optional(),
    }),
    resume: Yup.mixed().when("activeTab", {
      is: "career",
      then: (schema) => schema.required("Resume is required"),
      otherwise: (schema) => schema.optional(),
    }),
    candidatename: Yup.string().when("activeTab", {
      is: "career",
      then: (schema) => schema.required("Name is required"),
      otherwise: (schema) => schema.optional(),
    }),
    candidateemail: Yup.string().email("Invalid email format").when("activeTab", {
      is: "career",
      then: (schema) => schema.required("Email is required"),
      otherwise: (schema) => schema.optional(),
    }),
    candidatephone: Yup.string().when("activeTab", {
      is: "career",
      then: (schema) =>
        schema.required("Phone number is required")
          .matches(/^[0-9]+$/, "Phone number must contain only numbers") // Ensures numeric-only input
      ,
      otherwise: (schema) => schema.optional(),
    }),
    message: Yup.string().when("activeTab", {
      is: "career",
      then: (schema) => schema.required("Message is required"),
      otherwise: (schema) => schema.optional(),
    }),
  });

  // Initialize React Hook Form
  const {
    handleSubmit,
    control,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    context: { activeTab }
  });

  useEffect(() => {
    // Re-run validation when activeTab changes
    reset({ activeTab });
  }, [activeTab, reset]);

  useEffect(() => {
    console.log("Validation errors:", errors);
  }, [errors]);


  const handleBusinessSubmit = async (data) => {
    console.log("Business data - ", data);
    setIsButtonLoading(true);

    try {
      // Use axios to send a POST request
      const response = await axios.post(contact_url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Check if the request was successful
      if (response.status === 200) {
        toast.success("Form submitted successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        reset(); // Reset form fields if necessary
      } else {
        toast.error("Form submission failed", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('contactUrl:', contact_url);
      console.error('Error:', error);
      toast.error(error.message || "Server Error!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } finally {
      setIsButtonLoading(false);
    }
  };

  const handleCareerSubmit = async (data) => {
    console.log("Career data - ", data);
    setIsButtonLoading(true);

    // Prepare FormData object for file upload
    const formData = new FormData();
    formData.append("name", data.candidatename);
    formData.append("email", data.candidateemail);
    formData.append("phone", data.candidatephone);
    formData.append("message", data.candidatemessage);
    formData.append("resume", selectedFile);

    try {
      const response = await axios.post(contact_url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Handle response based on success status
      if (response.data.status === 1) {
        toast.success("Applied successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        reset(); // Reset form fields if necessary
      } else {
        toast.error(response.data.message || "Submission failed", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.message || "Server Error!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } finally {
      setIsButtonLoading(false);
    }
  };

  // Determine which submit function to use
  const onSubmit = (data) => {
    console.log("data - ", data);
    if (activeTab === 'business') {
      handleBusinessSubmit(data);
    } else if (activeTab === 'career') {
      handleCareerSubmit(data);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  return (
    <div style={{ background: "#fff", color: "#000" }}>
      <div className="contact-container"
        style={{
          marginLeft: "80px",
        }}>

        <div>
          <div className="container-fluid">
            <div className="col-lg-12" >
              <div className="tab-container" >
                <button
                  className={`tab-button ${activeTab === 'business' ? 'active business-active' : ''}`}
                  onClick={() => setActiveTab('business')}

                >
                  For business
                </button>
                <button
                  className={`tab-button ${activeTab === 'career' ? 'active career-active' : ''}`}
                  onClick={() => setActiveTab('career')}
                  style={{

                    backgroundColor: activeTab === 'career' ? "#1967d2" : "#fff",
                    color: activeTab === 'career' ? "#fff" : "#253284",
                    fontWeight: activeTab === 'career' ? "bold" : "normal"
                  }}
                >
                  For career
                </button>
              </div>
            </div>


            <div className="row m-0 contact-form-parent">
              <div
                className="contact-form-container"
                style={{
                  // display: "flex",
                  // justifyContent: "center",
                  width: "80%",
                  margin: 'none'
                  // marginTop:0,
                  // marginBottom: "30px",
                }}
              >
                <form
                  className="contact-form contact-style"
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ width: "100%" }}
                >

                  {activeTab === "business" && (
                    <>
                      <div
                        className="row align-items-center text-black"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: "100%", // This ensures the row spans full width
                        }}

                      >

                        <div
                          className="col-md-6"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "5px",
                          }}
                        >
                          <label
                            className="form-label text-md-right"
                            htmlFor="name"
                            style={{
                              // fontWeight: "bold",
                              marginBottom: "20px",
                              color: "#000",
                              fontSize: "20px",
                              letterSpacing: "1px"
                            }}
                          >
                            Name<span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                {...register('name')}
                                type="text"
                                id="name"
                                className="form-control input-style1"
                                placeholder="Full Name"
                              // style={{
                              //   color: "#000",
                              //   borderTop:'none',
                              //   borderLeft:'none',
                              //   borderRight:'none',
                              //   width:'80%'
                              // }}
                              />
                            )}
                          />
                          <p className="text-danger">
                            {errors.name?.message}
                          </p>
                        </div>


                        <div
                          className="col-md-6"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "5px",
                          }}
                        >
                          <label
                            className="form-label text-md-right"
                            htmlFor="country"
                            style={{
                              fontWeight: "bold",
                              // marginBottom: "20px",
                              border: '1px #ccc',
                              color: "#000",
                              fontSize: "20px",
                              letterSpacing: "1px"
                            }}
                          >
                            Country<span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                              <select
                                {...field}
                                id="country"
                                className="form-control input-style1"
                                {...register('country')}
                              // style={{
                              //   color: "#000",
                              //   borderRadius:'10px',
                              //   borderTop:'1px solid #ccc',
                              //   borderLeft:'1px solid #ccc',
                              //   borderRight:'1px solid #ccc',
                              //   padding :'18px',
                              //   width:'80%'
                              // }}
                              >
                                <option value="">Select Country</option>
                                {countryList.map((country) => (
                                  <option
                                    key={country.name}
                                    value={country.value}
                                  >
                                    {country.name.toUpperCase()} (
                                    {country.value})
                                  </option>
                                ))}
                              </select>
                            )}
                          />
                          <p className="text-danger">
                            {errors.country?.message}
                          </p>
                        </div>
                      </div>

                      {/* Email and Phone Fields */}
                      <div
                        className="row align-items-center text-black"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: "100%",
                        }}
                      >

                        <div
                          className="col-md-6"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "5px",
                          }}
                        >
                          <label
                            className="form-label text-md-right"
                            htmlFor="email"
                            style={{
                              // fontWeight: "bold",
                              marginBottom: "20px",
                              color: "#000",
                              fontSize: "20px",
                              letterSpacing: "1px"
                            }}
                          >
                            Email<span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="text"
                                id="email"
                                className="form-control input-style1"
                                placeholder="Enter your email"
                                {...register('email')}
                              // style={{
                              //   color: "#000",
                              //   borderTop:'none',
                              //   borderLeft:'none',
                              //   borderRight:'none',
                              //   width:'80%'
                              // }}
                              />
                            )}
                          />
                          <p className="text-danger">
                            {errors?.email?.message}
                          </p>
                        </div>

                        <div
                          className="col-md-6"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "5px",
                          }}
                        >
                          <label
                            className="form-label text-md-right"
                            htmlFor="phone"
                            style={{
                              // fontWeight: "bold",
                              marginBottom: "20px",
                              color: "#1f1f1f",
                              fontSize: "20px",
                              letterSpacing: "1px"
                            }}
                          >
                            Phone<span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="phone"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="text"
                                id="phone"
                                className="form-control input-style1"
                                placeholder=""
                                {...register('phone')}
                              // style={{
                              //   color: "#000",
                              //   borderTop:'none',
                              //   borderLeft:'none',
                              //   borderRight:'none',
                              //   width:'80%'
                              // }}
                              />
                            )}
                          />
                          <p className="text-danger">
                            {errors.phone?.message}
                          </p>
                        </div>

                        <div
                          className="col-md-12"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "5px",
                          }}
                        >
                          <label
                            className="form-label text-md-right"
                            htmlFor="message"
                            style={{
                              fontWeight: "bold",
                              marginBottom: "35px",
                              color: "#000",
                              fontSize: "20px",
                              letterSpacing: "1px"
                            }}
                          >
                            How Can We Help you?
                            <span className="text-danger">*</span>
                            <br></br>
                          </label>
                          <Controller
                            name="message"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="text"
                                id="candidatemessage"
                                className="form-control input-style1"
                                placeholder="Add details about projects.." // This acts as a label-like placeholder
                                style={{
                                  paddingBottom: "40px",
                                  color: "#000", // Sets main input text color
                                  fontSize: "20px",
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                }}
                                {...register('details')}
                              />
                            )}
                          />
                          <p className="text-danger">
                            {errors?.details?.message}
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  {activeTab === "career" && (
                    <>
                      <div
                        className="row align-items-center text-black"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: "100%", // This ensures the row spans full width
                        }}
                      >

                        <div
                          className="col-md-6"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "5px",
                          }}
                        >
                          <label
                            className="form-label text-md-right"
                            htmlFor="candidatename"
                            style={{
                              fontWeight: "bold",
                              marginBottom: "20px",
                              color: "#000",
                              fontSize: "20px",
                              letterSpacing: "1px"
                            }}
                          >
                            Name<span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="candidatename"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="text"
                                id="candidatename"
                                className="form-control input-style1"
                                placeholder="Full Name"
                                {...register('candidatename')}
                              // style={{
                              //   color: "#000",
                              //   borderTop:'none',
                              //   borderLeft:'none',
                              //   borderRight:'none',
                              //   width:'80%'
                              // }}
                              />
                            )}
                          />
                          <p className="text-danger">
                            {errors.candidatename?.message}
                          </p>
                        </div>

                        <div
                          className="col-md-6"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "10px",
                          }}
                        >
                          <label
                            className="form-label text-md-right"
                            htmlFor="candidateemail"
                            style={{
                              fontWeight: "bold",
                              marginBottom: "20px",
                              color: "#000",
                              fontSize: "20px",
                              letterSpacing: "1px"
                            }}
                          >
                            Email<span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="candidateemail"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="text"
                                id="candidateemail"
                                className="form-control input-style1"
                                placeholder="name@gmail.com"
                                {...register('candidateemail')}
                              // style={{
                              //   color: "#000",
                              //   borderTop:'none',
                              //   borderLeft:'none',
                              //   borderRight:'none',
                              //   width:'80%'
                              // }}
                              />
                            )}
                          />
                          <p className="text-danger">
                            {errors?.candidateemail?.message}
                          </p>
                        </div>
                      </div>

                      {/* Email and Phone Fields */}
                      <div
                        className="mb-4 row align-items-center text-black"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: "100%", // This ensures the row spans full width
                        }}
                      >
                        {/* Email Field */}

                        <div
                          className="col-md-6"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "5px",
                          }}
                        >
                          <label
                            className="form-label text-md-right"
                            htmlFor="candidatephone"
                            style={{
                              fontWeight: "bold",
                              marginBottom: "20px",
                              color: "#000",
                              fontSize: "20px",
                              letterSpacing: "1px"

                            }}
                          >
                            Phone<span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="candidatephone"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="text"
                                id="candidatephone"
                                className="form-control input-style1"
                                placeholder=""
                                {...register('candidatephone')}
                              // style={{
                              //   color: "#000",
                              //   borderTop:'none',
                              //   borderLeft:'none',
                              //   borderRight:'none',
                              //   width:'80%'
                              // }}
                              />
                            )}
                          />
                          <p className="text-danger">
                            {errors.candidatephone?.message}
                          </p>
                        </div>
                        <div
                          className="col-md-6"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "10px",
                          }}
                        >
                          <label
                            className="form-label text-md-right"
                            htmlFor="resume"
                            style={{
                              fontWeight: "bold",
                              marginBottom: "20px",
                              color: "#000",
                              fontSize: "20px",
                              letterSpacing: "1px"
                            }}
                          >
                            Attached your resume<span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="resume"
                            control={control}
                            placeholder="No file Chosen"
                            render={({ field }) => (
                              <input
                                // {...field}
                                type="file"
                                id="resume"
                                onChange={(e) => {
                                  handleFileChange(e);
                                  field.onChange(
                                    e.target.files[0]?.name
                                      ? e.target.files[0].name
                                      : null
                                  );
                                }}
                                className="form-control input-style1"
                                accept=".pdf,.doc,.docx,.png"
                              />
                            )}
                          />
                          <p className="text-danger">
                            {errors?.resume?.message}
                          </p>
                        </div>

                        <div
                          className="col-md-12"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "10px",
                          }}
                        >
                          <label
                            className="form-label text-md-right"
                            htmlFor="candidatemessage"
                            style={{
                              fontWeight: "bold",
                              marginBottom: "35px",
                              color: "#000",
                              fontSize: "20px",
                              letterSpacing: "1px"
                            }}
                          >
                            How Can We Help you?
                            <span className="text-danger">*</span>
                            <br></br>
                          </label>
                          <Controller
                            name="message"
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="text"
                                id="candidatemessage"
                                className="form-control input-style1"
                                placeholder="Leave your message..." // This acts as a label-like placeholder
                                style={{
                                  paddingBottom: "40px",
                                  color: "#000", // Sets main input text color
                                  fontSize: "20px",
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                }}
                                {...register('message')}
                              />
                            )}
                          />

                          <p className="text-danger">
                            {errors?.message?.message}
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-2 d-flex flex-column justify-content-start gap-4 contact-buton">
                    <button
                      type="submit"
                      className="btn btn-primary mb-4 border-radius-0"
                      disabled={isButtonLoading}
                    >
                      {isButtonLoading ? (
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>


                </form>
              </div>
            </div>
          </div>
        </div>


        <div className="row m-0">
          <div className="col-lg-12" >
            <ToastContainer />
          </div>
        </div>
      </div>

      <div style={{ width: "100vw", margin: "0", padding: "0" }}>
        <img
          src={`${config.baseUrl}/assets/img/banner/contact.png`}
          alt="contactImage"
          className="contact-image"
          style={{ margin: 0, width: "100%", height: "80%", display: "block" }} // Ensure the image takes the full width
        />
      </div>
    </div>
  );
};

export default Contactarea1;
