import React, { Component } from 'react';
import './Hire.css';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../utils/config';

class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      answers: [],
      questions: [
        'What role would you like to hire?',
        'How many people are employed at your company?',
        'What type of project are you hiring for?',
        'How long do you need the profession?',
        'What level of time commitment will you require from the role?',
        'What skills would you like to see in your new hire?',
        'When do you need the professional to start?',
        'Do you have an hourly rate in mind?',
        'Name',
        'Email',
        'Mobile Number',
      ],
      options: [
        [
          'Developers',
          'Designers',
          'Project Managers',
          'Product Managers',
          'Finance Experts',
          'Other', // Include "Other" as an option
        ],
        ['Less than 10', '11-50', '51-100', '101-500', '500+'],
        [
          'New idea or project',
          'Existing project that needs more resources',
          "None of the above, I'm just looking to learn more about Toptal",
        ],
        ['Less than 1 week', '1-4 weeks', '1-3 months', '3-6 months', 'Longer than 6 months', "I'll decide later"],
        [
          'Full time (40 or more hrs/week)',
          'Part time (Less than 40 hrs/week)',
          'Hourly',
          "I'll decide later",
          'Other', // Include "Other" as an option
        ],
        ['Technical Skills', 'Communication Skills', 'Project Management', 'Problem Solving', 'Other'], // Include "Other" as an option
        [
          'Immediately',
          'In 1-2 weeks',
          '6-12 months',
          'More than 2 weeks from now',
          "I'll decide later",
          'Other', // Include "Other" as an option
        ],
        [
          'Less than $75/hr',
          '$75 - $100/hr',
          '$101 - $125/hr',
          'More than $125/hr',
          'Not sure on budget yet',
          'Other', // Include "Other" as an option
        ],
      ],
      formData: {
        name: '',
        email: '',
        mobile: '',
      },
      loading: false, // Add loading state
    };
  }

  handleNextPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1,
    }));
  };

  handlePrevPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1,
    }));
  };

  handleAnswer = (answer) => {
    this.setState((prevState) => ({
      answers: [...prevState.answers.slice(0, this.state.currentPage), answer],
    }));
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleSubmit = async () => {
    this.setState({ loading: true }); // Start the loading indicator
    try {
      const response = await fetch('https://api.fuzionest.com/submit-quiz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          questions: this.state.questions.slice(0, 8),
          answers: this.state.answers.slice(0, 8),
          name: this.state.formData.name,
          email: this.state.formData.email,
          mobile: this.state.formData.mobile,
        }),
      });

      if (response.status === 200) {
        toast.success('Form submitted successfully', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        this.setState({
          formData: {
            name: '',
            email: '',
            mobile: '',
          },
          loading: false, // Stop the loading indicator
        });
      } else {
        toast.error('An error occurred while submitting the answers');
        this.setState({ loading: false }); // Stop the loading indicator on error
      }
    } catch (error) {
      toast.error('An error occurred while submitting the answers: ' + error.message);
      this.setState({ loading: false }); // Stop the loading indicator on error
    }
  };

  render() {
    const { currentPage, questions, options, formData, loading } = this.state;
    const currentOptions = options[currentPage];

    return (
      <div className="Quiz">
        <div className="Hire">
          <div className="flex justify-center items-center image-items w-100 h-12 mb-6 lg:mb-12">
            <Link to="/">
              <img
                // src="assets/img/fuzionest.png"
                src={`${config.baseUrl}/assets/img/fuzionest.png`}
                alt="Fuzionest"
                style={{ height: ' 50%', maxWidth: '30%', marginBottom: '10px' }}
              />
            </Link>
          </div>
          {currentPage === 0 && (
            <div className="box-hire">
              <p style={{ color: 'gray', fontWeight: '100', marginTop: '10px' }}>
                Thanks for your interest in hiring through Fuzionest! Before we get started, we'd like to ask a few
                questions to better understand your business needs.
              </p>
            </div>
          )}

          {currentPage < 8 ? (
            <div>
              <p className="hire-heading">{questions[currentPage]}</p>
              <div className="radio-options">
                {currentOptions.map((option, index) => (
                  <label key={index} className="custom-radio-label">
                    <input
                      type="radio"
                      name={`question${currentPage}`}
                      value={option}
                      checked={this.state.answers[currentPage] === option}
                      onChange={() => this.handleAnswer(option)}
                      style={{ marginRight: '15px' }}
                      className="custom-radio-input"
                      required
                    />
                    {option}
                    {option === 'Other' && this.state.answers[currentPage] === 'Other' && (
                     
                     <div>
                                             <textarea
                                               name={`otherText${currentPage}`}
                                               onChange={this.handleInputChange}
                                              value={this.state.formData[`otherText${currentPage}`]} // Use formData to set the value
                                                className="custom-textarea"
                                                style={{
                                                  border: 'none', // Remove the default border
                                                  borderBottom: '1px solid #000', // Add a 1px solid black bottom border
                                                  resize: 'none', // Remove resizing handles
                                                  outline: 'none', // Remove outline on focus
                                                  // marginTop: '5%',
                                                  height: '35px',
                                                  marginLeft: "4%",
                                               }}
                                              />
                                            </div>
                    )}
                  </label>
                ))}
              </div>

              <div className="button-container">
                {currentPage > 0 && (
                  <button
                    className="btn btn-border-base28"
                    style={{
                      marginRight: '10px',
                    }}
                    onClick={this.handlePrevPage}
                  >
                    Back
                  </button>
                )}

                <button
                  className="btn btn-border-base27"
                  onClick={this.handleNextPage}
                  disabled={this.state.answers[currentPage] === undefined || loading}
                >
                  {loading ? 'Submitting...' : 'Next'}
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p>All questions answered!</p>
              <div className="form-group">
                <label>Name<span style={{color: 'red'}}>*</span> </label>
                <input type="text" name="name" value={formData.name} onChange={this.handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Email<span style={{color: 'red'}}>*</span> </label>
                <input type="email" name="email" value={formData.email} onChange={this.handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Mobile<span style={{color: 'red'}}>*</span> </label>
                <input type="tel" name="mobile" value={formData.mobile} onChange={this.handleInputChange} required />
              </div>
              <button
                className="btn btn-border-base27"
                style={{ width: '20%' }}
                onClick={this.handleSubmit}
                disabled={!formData.name || !formData.email || !formData.mobile || loading}
              >
                {loading ? 'Submitting...' : 'Submit Answers'}
              </button>
            </div>
          )}
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default Quiz;
