import styles from './RealTimeVisibility.module.scss';

const RealTimeVisibility = () => {
  return (
    <section className={styles.realTimeVisibility}>
      <div className={styles.curvedCard}>
        <div className={styles.textContent}>
          <h2>
            <span className={styles.highlight}>Stay in con</span>trol with 24/7 visibility of your <span >ride</span>
          </h2>
          <p>
            Track every vehicle in real-time and keep an eye on your ride's location, speed, and status with up-to-the-minute updates. Utilize the playback feature to review past routes, analyze patterns, and optimize future trips. Ensure smooth operations and stay informed of every movement in your ride.
          </p>
        </div>

        <div className={styles.mapContent}>
          <img src="/assets/img/products/fleet_map.png" alt="Fleet map and control interface" className={styles.mapImage} />
        </div>
      </div>
    </section>
  );
};

export default RealTimeVisibility;
