import styles from '../Product/OtherFeatures.module.scss';

const OtherFeatures = () => {
  return (
    <section className={styles.otherFeaturesSection}>
  
      <div className={styles.featuresWrapper}>
        <h2>Budgeting & Forecasting Tools</h2>

        <div className={styles.featuresContainer}>
       
            <img
              src="/assets/img/products/Group2.png"
              alt="Simplify Your Ride Bookings"
              className={styles.featureImage}
            />
          
          <p>Plan for Success:<br></br>Empower your business with advanced budgeting and forecasting tools that help you plan for the future.</p>
        </div>
      </div>
    </section>
  );
};

export default OtherFeatures;
