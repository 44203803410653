import config from "../../utils/config";

// const CorePrincipleSection = () => {
//   return (
//     <div
//       className="core-container"
//       style={{ background: "#202020" }}
//     >
//       <div className="position-relative">
//         {/* <div>
//           <img
//             src={`${config.baseUrl}/assets/img/banner/fuzionestTeam.png`}
//             alt="Fuzionest Team"
//             style={{ width: "100%" }}
//           />
//         </div> */}
//         <div style={{ position: "relative", width: "100%" }}>
//           <img
//             src={`${config.baseUrl}/assets/img/landing/responsiblity.png`}
//             alt="Fuzionest Team"
//             style={{ width: "100%" }}
//           />
//           <div
//             style={{
//               position: "absolute",
//               bottom: 0,
//               left: 0,
//               right: 0,
//               height: "100%",
//               background: "linear-gradient(to top, black, transparent)",
//               zIndex: 1,
//             }}
//           />
//         </div>

//         <div className="row g-2 core-wrapper">
//           <div className="col-12">
//             <h2>Our core values guide how we operate</h2>
//           </div>

//           <div className="col-lg-3 col-md-6">
//             <div className="core-values">
//               <img
//                 src={`${config.baseUrl}/assets/img/icon/66.png`}
//                 alt="Flutter"
//                 width={60}
//               />

//               <div>
//                 <h2 style={{ marginTop: "5%" }}>We Iterate to Success</h2>
//                 <p>
//                   Success isn’t a straight path. We embrace our mistakes, learn
//                   from them, and use those lessons to advance toward our goals.
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-3 col-md-6">
//             <div className="core-values">
//               <img
//                 src={`${config.baseUrl}/assets/img/icon/66.png`}
//                 alt="Flutter"
//                 width={60}
//               />

//               <div>
//                 <h2 style={{ marginTop: "5%" }}>
//                   We Encourage Personal Growth
//                 </h2>
//                 <p>
//                   Great companies are built by great people. We support our
//                   employees in becoming the best versions of themselves, both
//                   personally and professionally.
//                 </p>
//               </div>
//             </div>
//           </div>

//           <div className="col-lg-3 col-md-6">
//             <div className="core-values">
//               <img
//                 src={`${config.baseUrl}/assets/img/icon/66.png`}
//                 alt="Smooth Transition"
//                 width={60}
//               />

//               <div>
//                 <h2 style={{ marginTop: "5%" }}>We Go the Extra Mile</h2>
//                 <p>
//                   Settling for mediocrity is easy. We strive to exceed
//                   expectations, whether tackling internal challenges or serving
//                   our customers.
//                 </p>
//               </div>
//             </div>
//           </div>

//           <div className="col-lg-3 col-md-6">
//             <div className="core-values">
//               <img
//                 src={`${config.baseUrl}/assets/img/icon/66.png`}
//                 alt="Smooth Transition"
//                 width={60}
//               />

//               <div>
//                 <h2 style={{ marginTop: "5%" }}>All While Having Fun</h2>
//                 <p>
//                   Work doesn’t have to be a grind or boring. We believe that the
//                   best results come from enjoying the process, not despite it.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CorePrincipleSection;
import React from "react";
import "./CoreValues.css"; // External CSS for styling
import { Link } from "react-router-dom";

const CoreValues = () => {
  return (
    <section className="core-values">
      <div className="core-values-container">
        {/* Core Values Header */}
        <p className="core-values-heading">
          Our core values guide how we operate
        </p>

        {/* Core Values Content */}
        <div className="core-values-content">
          {/* Value 1 */}
          <div className="core-value">
            <div className="icon">
              <img
                src={`${config.baseUrl}/assets/img/icon/33.png`}
                alt="Iterate to Success Icon"
              />
            </div>
            <h3>Integrity and Transparency </h3>
            <p>
              We are committed to honesty and openness in all client interactions, ensuring clear communication about our capabilities, timelines, and challenges. This helps us build trust and foster long-term relationships.
            </p>
          </div>

          {/* Value 2 */}
          <div className="core-value">
            <div className="icon">
              <img
                src={`${config.baseUrl}/assets/img/icon/66.png`}
                alt="Encourage Personal Growth Icon"
              />
            </div>
            <h3>Security and Privacy</h3>
            <p>
              We prioritize data security and client privacy, adhering to the highest standards of protection and compliance to safeguard your information. This commitment is especially important in an era of increasing concerns about data breaches.
            </p>
          </div>

          {/* Value 3 */}
          <div className="core-value">
            <div className="icon">
              <img
                src={`${config.baseUrl}/assets/img/icon/44.png`}
                alt="Go the Extra Mile Icon"
              />
            </div>
            <h3>Reliability and Accountability</h3>
            <p>
              We consistently deliver on our promises and take full responsibility for the outcomes of our projects. We are dedicated to meeting deadlines, maintaining system uptime, and providing dependable support
            </p>
          </div>
        </div>

        {/* Read More Button */}
        <div className="read-more">
          <Link to='/about'>
            <button className="read-more-button">
              Read more <span className="arrow">→</span>
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CoreValues;
