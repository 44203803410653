import React from "react";
import "./Stopthink.css";
import config from "../utils/config";
// import Image from '../../public/assets/img/5.png'

const Servicehomepage3 = () => {
  return (
    <div className="stopthink stopthink-services">
      <div>
        <img
          // src="assets/img/5.png"
          src={`${config.baseUrl}/assets/img/5.png`}
          alt="stopthink"
          className="left-image"
          id="stopthinkimage"
          style={{ width: "25%" }}
        />
      </div>

      <div className="contentStop">
        <div className="text-content">
          <h2 className="Stop-heading"> Stop Thinking & Start Building!</h2>
          <p className="Stop-para" style={{ color: "white" }}>
            Please contact us for further guidance and our expert will be in
            touch with you as soon as possible.
          </p>
        </div>
      </div>

      <div>
        <img
          src="assets/img/5.png"
          alt="Start Building"
          className="right-image"
          style={{ width: "25%" }}
        />
      </div>

    </div>
  );
};

export default Servicehomepage3;
