import React from "react";
import ContactAreaOne from "../components/ContactAreaOne";
import Contactarea1 from "../components/Contactarea1";
import FooterFive from "../components/footerFive";
import NavBar from "../components/NavBar";
import ContactArea2 from "../components/ContactArea2";
import { Helmet } from "react-helmet";
import AddressSection from "../components/AddressSection";

const Contact = () => {
  const browserTabTitle =
    "Get in Touch with Fuzionest: Contact Us for Expert Technology Solutions";
  const seoTitle =
    "Get in Touch with Fuzionest: Contact Us for Expert Technology Solutions";
  const seoDescription =
    "Contact Fuzionest for expert technology solutions and personalized consultation. Whether you need tech consulting, project development, or AI-driven automation, our team is ready to assist. Reach out today to start transforming your business with innovative solutions tailored to your needs.";
  const seoKeywords =
    "Contact fuzionest, get in touch, tech consulting contact, project development inquiry, ai automation support, business technology consultation, customer support, contact information, reach out, connect with us.";

  return (
    <>
      <Helmet>
        <title>{browserTabTitle}</title>
        <meta
          name="description"
          content={seoDescription}
        />
        <meta
          name="keywords"
          content={seoKeywords}
        />
        <meta
          property="og:title"
          content={seoTitle}
        />
        <meta
          property="og:description"
          content={seoDescription}
        />
        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ContactPage",
              "name": "${browserTabTitle}",
              "url": "https://www.fuzionest.com/contact",
              "logo": "https://www.fuzionest.com/assets/img/landing/FuzionestLogo.svg",
              "description": "${seoDescription}",
              "contactPoint": [
                {
                  "@type": "ContactPoint",
                  "contactType": "Customer Service",
                  "telephone": "+91 84387 62921",
                  "email": "contact@fuzionest.com",
                  "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Coimbatore",
                    "addressRegion": "Tamil Nadu",
                    "postalCode": "641014",
                    "addressCountry": "India",
                    "streetAddress": "Tidel Park, Module No.12 Ground floor Elcot SEZ Coimbatore IT Park Road"
                  }
                },
                {
                  "@type": "ContactPoint",
                  "contactType": "Customer Service",
                  "telephone": "+91 84387 62921",
                  "email": "contact@fuzionest.com",
                  "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Coimbatore",
                    "addressRegion": "Tamil Nadu",
                    "postalCode": "641014",
                    "addressCountry": "India",
                    "streetAddress": "No. 11/4, Pooja Garden, Kalapatti Main Rd, Civil Aerodrome Post"
                  }
                }
              ]
            }
          `}
        </script>
      </Helmet>
      {/* Navigation Bar */}
      <NavBar />
      <ContactAreaOne />
      <Contactarea1 />

      <AddressSection />
      <ContactArea2 />
      <FooterFive />
    </>
  );
};

export default Contact;
