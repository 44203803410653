import styles from '../Product/RealTimeVisibility.module.scss';

const RealTimeVisibility = () => {
  return (
    <section className={styles.realTimeVisibility}>
      <div className={styles.curvedCard}>
        <div className={styles.textContent}>
          <h2>
          <span className={styles.highlight}> Always u</span>p-to-date
          </h2>
          <p>
          Make informed decisions with real-time data synchronization across all platforms..
          </p>
        </div>

        <div className={styles.mapContent}>
          <img src="/assets/img/products/DashboardNavigation.png" alt="Fleet map and control interface" className={styles.mapImage} />
        </div>
      </div>
    </section>
  );
};

export default RealTimeVisibility;
