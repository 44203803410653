import styles from "./HeroSection.module.scss";
import { Link, useNavigate } from "react-router-dom";


const HeroSection = () => {

  const navigate = useNavigate();

  const handleStartNowClick = () => {
    navigate("/products/fuzion-books/start-now");
  };

  const handleDemoClick = () => {
    navigate("https://calendly.com/contact-mmm");
  };


  return (
    <section className={styles.hero}>
      <h1>
        <span className={styles.highlight}>FuzionBooks</span>
      </h1>
      <div className={styles.para}>
        <p >
          Streamline your financial management with FuzionBooks, the smart solution for seamless accounting. Empower your business with automated processes and real-time financial insights.
        </p>
      </div>

      <div className={styles.buttons}>
        <button className={styles.primaryBtn} onClick={handleStartNowClick}>Start now - It's free</button>
        <Link to="https://calendly.com/contact-mmm">
          <button className={styles.secondaryBtn}>Schedule a demo</button>
        </Link>
      </div>

    </section>
  );
};

export default HeroSection;
