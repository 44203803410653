import StartNow from "../../../components/Fleet/Books/StartNowForm";
import FooterFive from "../../../components/footerFive";
import { ToastContainer } from 'react-toastify';
import NavBar from "../../../components/NavBar";


const MainPage = () => {
  return (
    <>
      <NavBar />
      <StartNow />
      <ToastContainer />
      <FooterFive />
    </>
  );
};

export default MainPage;
