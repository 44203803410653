import React from "react";
import "./Job2.css";
import { Link } from "react-router-dom";
import Jobs from "../utils/jobs.json";
const jobData = Jobs;

function Job2() {
  return (
    <div className="job2" style={{ padding: "5%", background: "#202020" }}>
      <div className="title">
        <h2 className="title-job2 text-white">
          Get Your Best Profession With Fuzionest
          {/* <span style={{ color: "#202021" }}>Fuzionest</span> */}
        </h2>
        <p className="content-job2" style={{ marginBottom: "5%" }}>
          We're your strategic partner in harnessing the power of technology to
          drive innovation, efficiency, and growth. With a passion for creating
          exceptional solutions, we're dedicated to helping businesses of all
          sizes navigate the ever-evolving digital landscape.
        </p>
      </div>

      <div className="job2">
        <div className="grid-container-job job-container">
          {jobData.map((job) => (
            <div className="grid-item-job" key={job.id}>
              <div
                className="box-container"
                style={{
                  color: "#202020",
                  borderRadius: "10px",
                }}
              >
                <div className="box">
                  <h6 className="heading-job2-h6">{job.title}</h6>
                  <p className="para-job2-p" style={{ marginBottom: "7%" }}>
                    {job.desc}
                  </p>
                  <div className="grid-container-job2-2">
                    <div className="grid-item-content-job2-2">
                      <p>{job.jobType}</p>
                    </div>

                    <Link
                      to={{
                        pathname: "/applyJob",
                        search: `?jobId=${job.id}`, // Constructing query parameter
                      }}
                      style={{ textDecoration: "underline" }}
                    >
                      Read more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Job2;
