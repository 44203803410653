import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import CookiePolicyHero from "../components/CookiePolicyHero";
import CookiePolicyContent from "../components/CookiePolicyContent";

const CookiePolicy = () => {
  return (
    <>
      <NavBar />
      <CookiePolicyHero />
      <CookiePolicyContent />
      <Footer />
    </>
  );
};

export default CookiePolicy;
