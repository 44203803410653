import styles from './SecurityFeatures.module.scss';

const SecurityFeatures = () => {
  return (
    <section className={styles.securityFeaturesSection}>
      <div className={styles.featuresContainer}>
        <div className={styles.featureCard}>
          <h3>1. Cloud-Based System Powered by AWS:</h3>
          <p>
            <span >Ride</span>X is built on the robust and scalable AWS cloud infrastructure, ensuring reliability, high availability, and global access. AWS also offers enhanced performance, security, and compliance features to support businesses of all sizes.
          </p>
        </div>

        <div className={styles.featureCard}>
          <h3>2. Data Encryption</h3>
          <p>
            All <span >ride</span> data is encrypted both in transit and at rest, ensuring the utmost protection of sensitive information such as vehicle locations, driver details, and operational data.
          </p>

        </div>

        <div className={styles.featureCard}>
          <h3>3. Secure User Access Control</h3>
          <p>
            Role-based access ensures that only authorized personnel have access to specific parts of the system. This granular access control enhances security and protects sensitive data from unauthorized users.
          </p>
        </div>

        <div className={styles.featureCard}>
          <h3>4. Regular Backups</h3>
          <p>
            Automatic and frequent backups safeguard your data, ensuring continuity and recovery in the event of an outage or system failure.
          </p>
        </div>

        <div className={styles.featureCard}>
          <h3>5. Scalability</h3>
          <p>
            Leveraging AWS infrastructure, <span >Ride</span>X is designed to seamlessly scale with your business, whether you operate a small <span >ride</span> or manage thousands of vehicles across multiple locations.
          </p>
        </div>

        <div className={styles.featureCard}>
          <h3>6. Comprehensive Documentation</h3>
          <p>
            <span >Ride</span>X is fully documented, providing clear, step-by-step guides and API references. Our extensive documentation helps both technical and non-technical users quickly navigate the system, integrate third-party tools, and resolve issues efficiently.
          </p>
        </div>

        <div className={styles.featureCard}>
          <h3>7. Compliance with Data Privacy Regulations</h3>
          <p>
            <span >Ride</span>X adheres to major data privacy regulations like GDPR and CCPA, ensuring that your data is securely managed and legally compliant.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SecurityFeatures;
