import styles from '../Product/OtherFeatures.module.scss';

const OtherFeatures = () => {
  return (
    <section className={styles.otherFeaturesSection}>
  
      <div className={styles.featuresWrapper}>
        <h2>User-friendly Dashboard and Analytics</h2>

        <div className={styles.featuresContainer}>
       
            <img
              src="/assets/img/products/userFriendlyDashboard.png"
              alt="Simplify Your Ride Bookings"
              className={styles.featureImage}
            />
          
          <p><strong>Insights at a Glances:</strong> <br></br>Navigate with ease and gain insights instantly with our intuitive dashboard and analytics.</p>
        </div>
      </div>
    </section>
  );
};

export default OtherFeatures;
