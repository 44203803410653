import HeroSection from "../../../components/Fleet/Books/HeroSection";
import RealTimeTracking from "../../../components/Fleet/Books/RealTimeTracking";
import RealTimeVisibility from "../../../components/Fleet/Books/RealTimeVisibility";
import YellowHighlight from "../../../components/Fleet/Product/YellowHighlight";
import DriverScheduling from "../../../components/Fleet/Books/DriverScheduling";
import DataPriority from "../../../components/Fleet/Books/DataPriority";
import ActionalbleSection from "../../../components/Fleet/Books/ActionalbleSection";
import GrowWithoutLimits from "../../../components/Fleet/Books/GrowWithoutLimits";
import OtherFeatures from "../../../components/Fleet/Books/OtherFeatures";
import Others from "../../../components/Fleet/Books/others";
import RealTimeGpsTracking from "../../../components/Fleet/Books/RealTimeGpsTracking";
import SecurityFeatures from "../../../components/Fleet/Books/SecurityFeatures";
import Security from "../../../components/Fleet/Books/Security";
import CallToAction from "../../../components/Fleet/Books/CallToAction";
import FooterFive from "../../../components/footerFive";
import NavBar from "../../../components/NavBar";


const MainPage = () => {
  return (
    <>
      <NavBar />
      <HeroSection />
      <RealTimeTracking />
      <YellowHighlight title="Real-time Data Synchronization"/>
      <RealTimeVisibility />
      <YellowHighlight title="Multi-Channel Integration"/>
      <ActionalbleSection />
      <YellowHighlight title="Tax Compliance and Management"/>
      <DriverScheduling />
      <OtherFeatures />
      <YellowHighlight title="Expense Tracking and Management"/>
      <RealTimeGpsTracking />
      <SecurityFeatures />
      <YellowHighlight title="Secure Data Management"/>
      <DataPriority />
      <YellowHighlight title="Scalability"/>
      <GrowWithoutLimits/>
      <Others/>
      <YellowHighlight title="Technology & Security"/>
      <Security />
      <CallToAction />  
      <FooterFive />
    </>
  );
};

export default MainPage;
