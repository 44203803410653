import React from "react";
import { Link } from "react-router-dom";
import config from "../utils/config";

const Servicepagetwo = () => {
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div
        className="faq-area faq-area-margin-top bg-cover pd-bottom-110 "
        style={{ background: "#ffffff", padding: "30px 0" }}
      >
        <div className="">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 col-md-8"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner pt-lg-4">
                <img
                  className="Servicepagetwo-img"
                  src={`${config.baseUrl}/assets/img/landing/About.png`}
                  alt="About Fuzionest"
                  style={{
                    width: "100%",         // Ensures the image fits within its container
                    maxWidth: "500px",      // Adjust as needed to control maximum size
                    height: "auto",         // Maintains the image’s aspect ratio
                    objectFit: "cover",     // Keeps image from stretching
                  }}
                />
              </div>
            </div>
            <div
              className="col-xl-7 col-lg-6"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-service2">
                <div className="center-div-about">
                  <h3
                    className="title2"
                    style={{
                      marginTop: "20px",

                      marginBottom: "5%",
                      color: "#111827",
                    }}
                  >
                    About Us
                  </h3>
                </div>
                <p
                  className="contents2"
                  style={{
                    color: "#454B57",
                    marginTop: "10px",
                    fontWeight: 400,
                  }}
                >
                  Fuzionest thrives on innovation. Our agency continually seeks
                  out new technologies, frameworks, and methodologies to stay
                  ahead in the rapidly evolving world of software development.
                  We embrace change and use it to create cutting-edge solutions
                  that give our clients a competitive advantage.
                </p>
                <Link to="/about" className="center-div-about">
                  <button className="btn btn-base border-radius-0 contents2">
                    Read more
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Servicepagetwo;
