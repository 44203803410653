import styles from '../Product/RealTimeTracking.module.scss';

const RealTimeTracking = () => {
  return (
    <section className={styles.realTimeSection}>
      <div className={styles.topTagline}>
        <img src="/assets/img/products/painflower.png" alt="Decorative icon" className={styles.taglineIcon} />
        <p className={styles.taglineText}>Automated Financial Reporting</p>
      </div>

      <div className={styles.topContent}>
        <img src="/assets/img/products/OBJECTS.png" alt="Woman Illustration" className={styles.womanImage} />
        <p className={styles.description}>
          <strong>Efficiency Redefined</strong> Transform your reporting process with automated financial statements that save you hours and reduce errors.
        </p>
      </div>
    </section>
  );
};

export default RealTimeTracking;
