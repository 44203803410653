import React, { useState, useEffect } from "react";
import styles from "./StartNow.module.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import countryList from "../../../utils/country.json";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import Swal from "sweetalert2";
import { isValidPhoneNumber } from 'libphonenumber-js';
import { googleLogout } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import config from "../../../utils/config";
const apiUrl = process.env.REACT_APP_FLEET_URL;
const token = process.env.REACT_APP_FLEET_TOKEN;



// Validation schema using Yup
const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string()
    .matches(/(^\+\d{1,3}\s?\d{7,12}$|^\d{7,12}$)/, "Phone number is not valid")
    .required("Phone number is required"),
  password: yup
    .string()
    .matches(/(?=.*[a-z].*[a-z])/, "Must have at least 2 lowercase characters")
    .matches(/(?=.*[A-Z].*[A-Z])/, "Must have at least 2 uppercase characters")
    .matches(/(?=.*\d.*\d)/, "Must have at least 2 numbers")
    .matches(
      /(?=.*[!@#$%^&*()_\-+=;:'",<.>/?].*[!@#$%^&*()_\-+=;:'",<.>/?])/,
      "Must have at least 2 special characters"
    )
    .required("Password is required"),
});

const SecurityFeatures = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  const [selectedCountryCode, setSelectedCountryCode] = useState("+91"); // Default country code for India
  const [selectedCountryName, setSelectedCountryName] = useState("India");
  const [selectedCountryValue, setSelectedCountryValue] = useState("India");
  const [selectedState, setSelectedState] = useState("Tamil Nadu"); // Default state name
  const [submitError, setSubmitError] = useState(""); // State for handling submit errors
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleCountryCodeChange = (e) => {
    const selectedCountry = countryList.find(
      (country) => country.value === e.target.value
    );
    setSelectedCountryCode(e.target.value);
    setSelectedCountryName(selectedCountry?.fullname || "");
    setSelectedCountryValue(selectedCountry.name)
    setValue("country", selectedCountry?.fullname || "");
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };


  const validatePhoneNumber = (phone, countryCode) => {
    const fullPhoneNumber = phone.replace(/\s/g, '');
    // console.log("phone", fullPhoneNumber);
    // console.log("countryCode", countryCode);

    return isValidPhoneNumber(`${countryCode}${fullPhoneNumber}`);
  };

  const onSubmit = async (data) => {
    try {

      if (!validatePhoneNumber(data.phone, selectedCountryCode)) {
        toast.error("Phone number is not valid", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        return;
      }

      setIsButtonLoading(true);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          signupType: "manual",
          phone: data.phone,
          country_code: selectedCountryCode,
          password: data.password,
          country: selectedCountryName,
          country_state: selectedState,
          serviceurl: "https://books.zoho.in/home?cs=true&websignup=true",
          servicename: "ZohoBooks",
          temp_redirect: "true",
        }),
      });

      const jsonResponse = await response.json();

      if (jsonResponse.status === 1) {
        Swal.fire({
          title: jsonResponse?.message || "Signed up successfully",
          // text: "Click 'OK' to go to the homepage or 'Cancel' to stay on this page.",
          icon: 'success',
          showCancelButton: true,
          confirmButtonText: 'Go to back',
          cancelButtonText: 'Cancel',
        }).then((result) => {
          if (result.isConfirmed) {
            setIsButtonLoading(false);
            navigate(-1); // Navigate to homepage or a specific route
          } else {
            setIsButtonLoading(false);
          }
        });

      } else {
        toast.error("Signup failed: " + jsonResponse.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        setIsButtonLoading(false);
      }
    } catch (error) {
      console.error("Error during signup:", error);
      toast.error("An error occurred. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      setIsButtonLoading(false);
    }
  };

  const handleGoogleSuccess = async (response) => {
    // console.log("Google User Details:", response);

    const decodedToken = jwtDecode(response.credential);
    // console.log("Google User Details:", decodedToken);

    const requestBody = {
      name: decodedToken.name,
      email: decodedToken.email,
      signupType: "social",
      account_id: decodedToken.sub,
      serviceurl: "https://books.zoho.in/home?cs=true&websignup=true",
      servicename: "ZohoBooks",
      temp_redirect: "true",
      provider: "google",
    };

    const apiResponse = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    });

    // if (!apiResponse.ok) {
    //   throw new Error("Network response was not ok");
    // }

    const data = await apiResponse.json();


    if (data.status === 1) {
      toast.success(data.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      toast.error("Signup failed: " + data.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    console.log("API Response:", data);

    await googleLogout();

  };


  return (
    <section className={styles.securityFeaturesSection}>
      <div className={styles.featuresContainer}>
        <div className={styles.featureCard}>
          <h2><span >FuzionB</span>ooks</h2>
          <form
            className={styles.formContainer}
            onSubmit={handleSubmit(onSubmit)}
          >
            {submitError && <p className="errorMessage">{submitError}</p>}
            <div className={styles.formGroup}>
              <label>
                Name<span className={styles.required}>*</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="e.g. John Smith"
                {...register("name")}
                className="form-control"
              />
              {errors.name && (
                <p className="errorMessage">{errors.name.message}</p>
              )}
            </div>

            <div className={styles.formGroup}>
              <label>
                Email ID<span className={styles.required}>*</span>
              </label>
              <input
                type="email"
                name="email"
                placeholder="e.g. johnsmith@gmail.com"
                {...register("email")}
                className="form-control"
              />
              {errors.email && (
                <p className="errorMessage">{errors.email.message}</p>
              )}
            </div>

            <div className={styles.formGroup}>
              <label>
                Password<span className={styles.required}>*</span>
              </label>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                {...register("password")}
                className="form-control"
              />
              {errors.password && (
                <p className="errorMessage">{errors.password.message}</p>
              )}
            </div>

            <div className={styles.formGroup}>
              <label>
                Phone Number<span className={styles.required}>*</span>
              </label>
              <div style={{ display: "flex", gap: "10px" }}>
                <select
                  value={selectedCountryCode}
                  onChange={handleCountryCodeChange}
                  className="form-control"
                  style={{ width: "100px" }}
                >
                  <option value=""></option>
                  {countryList.map((country) => (
                    <option key={country.name} value={country.value}>
                      {country.value}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  name="phone"
                  placeholder="e.g. 6894320912"
                  {...register("phone")}
                  className="form-control"
                />
              </div>
              {errors.phone && (
                <p className="errorMessage">{errors.phone.message}</p>
              )}
            </div>


            <div className={styles.formGroup}>
              <label>Country</label>
              <input
                type="text"
                name="country"
                value={selectedCountryName}
                readOnly
                className="form-control"
              />
            </div>

            <div className={styles.formGroup}>
              <label>State</label>
              <input
                type="text"
                name="state"
                value={selectedState}
                onChange={handleStateChange}
                className="form-control"
              />
            </div>

            <div className={styles.terms}>
              <p className={styles.terms}>
                By clicking on Start Now, you accept our{" "}
                <a href="/subscription-agreement">Subscription Agreement</a> and{" "}
                <a href="/privacy-policy">Privacy Policy</a>
              </p>
            </div>

            <button disabled={isButtonLoading} type="submit" className={styles.submitBtn}>
              {isButtonLoading ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Start Now")}
            </button>
          </form>

          <div className={styles.orContainer}>
            <span className={styles.line}></span>
            <p className={styles.orText}>Or</p>
            <span className={styles.line}></span>
          </div>

          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={() => {
              console.error("Login Failed");
              toast.error("Google login failed. Please try again.");
            }}

          />
        </div>

        <div className={styles.featureCard1}>
          <img
            src={`${config.baseUrl}/assets/img/products/form/AllDevices.png`}
            alt="start"
          />


        </div>
      </div>
    </section>
  );
};

export default SecurityFeatures;

// import React, { useState } from "react";
// import styles from "./StartNow.module.scss";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import countryList from "../../../utils/country.json";
// import { toast } from "react-toastify";
// import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";

// const apiUrl = process.env.REACT_APP_FLEET_URL;
// const token = process.env.REACT_APP_FLEET_TOKEN;

// // Validation schema using Yup
// const validationSchema = yup.object().shape({
//   name: yup.string().required("Name is required"),
//   email: yup
//     .string()
//     .email("Enter a valid email")
//     .required("Email is required"),
//   phone: yup
//     .string()
//     .matches(/(^\+\d{1,3}\s?\d{7,12}$|^\d{7,12}$)/, "Phone number is not valid")
//     .required("Phone number is required"),
//   password: yup
//     .string()
//     .matches(/(?=.*[a-z].*[a-z])/, "Must have at least 2 lowercase characters")
//     .matches(/(?=.*[A-Z].*[A-Z])/, "Must have at least 2 uppercase characters")
//     .matches(/(?=.*\d.*\d)/, "Must have at least 2 numbers")
//     .matches(
//       /(?=.*[!@#$%^&*()_\-+=;:'",<.>/?].*[!@#$%^&*()_\-+=;:'",<.>/?])/,
//       "Must have at least 2 special characters"
//     )
//     .required("Password is required"),
// });

// const SecurityFeatures = () => {
//   const {
//     handleSubmit,
//     formState: { errors },
//     register,
//     setValue,
//   } = useForm({
//     resolver: yupResolver(validationSchema),
//   });

//   const navigate = useNavigate();
//   const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
//   const [selectedCountryName, setSelectedCountryName] = useState("India");
//   const [selectedState, setSelectedState] = useState("Tamil Nadu");
//   const [submitError, setSubmitError] = useState("");

//   const handleCountryCodeChange = (e) => {
//     const selectedCountry = countryList.find(
//       (country) => country.value === e.target.value
//     );
//     setSelectedCountryCode(e.target.value);
//     setSelectedCountryName(selectedCountry?.fullname || "");
//     setValue("country", selectedCountry?.fullname || "");
//   };

//   const handleStateChange = (event) => {
//     setSelectedState(event.target.value);
//   };

//   const handleGoogleSuccess = async (response) => {
//     const decodedToken = jwtDecode(response.credential);

//     const requestBody = {
//       name: decodedToken.name,
//       email: decodedToken.email,
//       signupType: "social",
//       account_id: decodedToken.sub,
//       serviceurl: "https://books.zoho.in/home?cs=true&websignup=true",
//       servicename: "ZohoBooks",
//       temp_redirect: "true",
//       provider: "google",
//     };

//     const apiResponse = await fetch(apiUrl, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(requestBody),
//     });

//     const data = await apiResponse.json();

//     if (data.status === 1) {
//       toast.success(data.message, {
//         position: toast.POSITION.TOP_CENTER,
//         autoClose: 3000,
//       });
//     } else {
//       toast.error("Signup failed: " + data.message, {
//         position: toast.POSITION.TOP_CENTER,
//         autoClose: 3000,
//       });
//     }
//   };

//   const onSubmit = async (data) => {
//     try {
//       const response = await fetch(apiUrl, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           name: data.name,
//           email: data.email,
//           signupType: "manual",
//           phone: data.phone,
//           country_code: selectedCountryCode,
//           password: data.password,
//         }),
//       });

//       const jsonResponse = await response.json();

//       if (jsonResponse.status === 1) {
//         Swal.fire({
//           title: 'Are you sure?',
//           text: "Click 'OK' to go to the homepage or 'Cancel' to stay on this page.",
//           icon: 'question',
//           showCancelButton: true,
//           confirmButtonText: 'OK',
//           cancelButtonText: 'Cancel',
//         }).then((result) => {
//           if (result.isConfirmed) {
//             navigate('/contact'); // Navigate to homepage or a specific route
//           }
//         });
//       } else {
//         toast.error("Signup failed: " + jsonResponse.message, {
//           position: toast.POSITION.TOP_CENTER,
//           autoClose: 3000,
//         });
//       }
//     } catch (error) {
//       setSubmitError("An error occurred. Please try again.");
//     }
//   };

//   return (
//     <section className={styles.securityFeaturesSection}>
//       <div className={styles.featuresContainer}>
//         <div className={styles.featureCard}>
//           <h2><span >FuzionB</span>ooks</h2>
//           <form
//             className={styles.formContainer}
//             onSubmit={handleSubmit(onSubmit)}
//           >
//             {submitError && <p className="errorMessage">{submitError}</p>}
//             <div className={styles.formGroup}>
//               <label>
//                 Name<span className={styles.required}>*</span>
//               </label>
//               <input
//                 type="text"
//                 name="name"
//                 placeholder="e.g. John Smith"
//                 {...register("name")}
//                 className="form-control"
//               />
//               {errors.name && (
//                 <p className="errorMessage">{errors.name.message}</p>
//               )}
//             </div>

//             <div className={styles.formGroup}>
//               <label>
//                 Email ID<span className={styles.required}>*</span>
//               </label>
//               <input
//                 type="email"
//                 name="email"
//                 placeholder="e.g. johnsmith@gmail.com"
//                 {...register("email")}
//                 className="form-control"
//               />
//               {errors.email && (
//                 <p className="errorMessage">{errors.email.message}</p>
//               )}
//             </div>

//             <div className={styles.formGroup}>
//               <label>
//                 Password<span className={styles.required}>*</span>
//               </label>
//               <input
//                 type="password"
//                 name="password"
//                 placeholder="Enter your password"
//                 {...register("password")}
//                 className="form-control"
//               />
//               {errors.password && (
//                 <p className="errorMessage">{errors.password.message}</p>
//               )}
//             </div>

//             <div className={styles.formGroup}>
//               <label>
//                 Phone Number<span className={styles.required}>*</span>
//               </label>
//               <div style={{ display: "flex", gap: "10px" }}>
//                 <select
//                   value={selectedCountryCode}
//                   onChange={handleCountryCodeChange}
//                   className="form-control"
//                   style={{ width: "100px" }}
//                 >
//                   <option value=""></option>
//                   {countryList.map((country) => (
//                     <option key={country.name} value={country.value}>
//                       {country.value}
//                     </option>
//                   ))}
//                 </select>
//                 <input
//                   type="text"
//                   name="phone"
//                   placeholder="e.g. 6894320912"
//                   {...register("phone")}
//                   className="form-control"
//                 />
//               </div>
//               {errors.phone && (
//                 <p className="errorMessage">{errors.phone.message}</p>
//               )}
//             </div>


//             <div className={styles.formGroup}>
//               <label>Country</label>
//               <input
//                 type="text"
//                 name="country"
//                 value={selectedCountryName}
//                 readOnly
//                 className="form-control"
//               />
//             </div>

//             <div className={styles.formGroup}>
//               <label>State</label>
//               <input
//                 type="text"
//                 name="state"
//                 value={selectedState}
//                 onChange={handleStateChange}
//                 className="form-control"
//               />
//             </div>

//             <div className={styles.terms}>
//               <p className={styles.terms}>
//                 By clicking on Start Now, you accept our{" "}
//                 <a href="/subscription-agreement">Subscription Agreement</a> and{" "}
//                 <a href="/privacy-policy">Privacy Policy</a>
//               </p>
//             </div>

//             <button type="submit" className={styles.submitBtn}>
//               Start Now
//             </button>
//           </form>

//           <div className={styles.orContainer}>
//             <span className={styles.line}></span>
//             <p className={styles.orText}>Or</p>
//             <span className={styles.line}></span>
//           </div>

//           <GoogleLogin
//             onSuccess={handleGoogleSuccess}
//             onError={() => {
//               console.error("Login Failed");
//               toast.error("Google login failed. Please try again.");
//             }}

//           />
//         </div>

//         <div className={styles.featureCard1}>
//           <img
//             src="/assets/img/products/form/allDevices.png"
//             alt="start"
//           />
//         </div>
//       </div>
//     </section>
//   );
// };

// export default SecurityFeatures;


