import { Link } from "react-router-dom";
import config from "../../utils/config";

const ResponsibilitySection = () => {
  return (
    <div style={{ background: "##f0f6fa" }}>
      <div className="container py-4">
        <div className="d-flex justify-content-center">
          <img
            src={`${config.baseUrl}/assets/img/icon/shield.png`}
            alt="Shield"
            width={80}
          />
        </div>
        <div className="security-content">
          <h2
            className="m-0"
            style={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Your privacy is our responsibility
          </h2>
          <p>
            We believe that trust is paramount in a relationship. We do not own
            or sell your data, and we most certainly do not bank on
            advertising-based business models. The only way we make money is
            from the software license fees you pay us.
          </p>
          <Link to="/tailored-solutions">
            <button
              className="btn btn-base border-radius-0"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              Learn More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResponsibilitySection;
