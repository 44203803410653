import styles from "./HeroSection.module.scss";
import { Link, useNavigate } from "react-router-dom";



const HeroSection = () => {
  const navigate = useNavigate();

  const handleStartNowClick = () => {
    navigate("/products/ridex/start-now");
  };

  const handleDemoClick = () => {
    navigate("/fleet/products/demo");
  };

  return (
    <section className={styles.hero}>
      <h1>
        <span className={styles.highlight}>RideX</span>
        <br />
      </h1>
      <h2>
        Optimize Your <span >Ride</span><span >  ,Maximize Efficiency</span>
      </h2>

      <div className={styles.buttons}>
        <button className={styles.primaryBtn} onClick={handleStartNowClick}>Start now - It's free</button>
        <Link to="https://calendly.com/contact-mmm">
          <button className={styles.secondaryBtn}>Schedule a demo</button>
        </Link>
      </div>
    </section>
  );
};

export default HeroSection;
