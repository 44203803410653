import React from "react";
import "react-toastify/dist/ReactToastify.css"; // Import the styles

function AddressSection() {
  return (
    <div className="address-container" style={{ background: "#fff" }}>
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start", // Aligns items at the start vertically
          padding: "20px", // Add padding if needed for spacing
        }}
      >
        {/* Left side - Title */}
        <div
          className="col-md-5"
          style={{
            alignSelf: "flex-start", // Aligns title to the start
          }}
        >
          <p style={{ color: "#202021", fontSize: "40px", letterSpacing: "px", lineHeight: 1.1 }}>We are located at various places</p>
        </div>

        {/* Right side - Address */}
        <div
          className="col-md-3"
          style={{
            alignSelf: "flex-start", // Aligns address to the end
            textAlign: "flex-start", // Align text to the right

          }}
        >
          <p style={{ color: "#253284", fontSize: "20px" }}>Address 1</p>
          <p>
            Tidel Park, Module No.12 Ground floor Elcot SEZ Coimbatore IT Park
            Road, Coimbatore, Tamil Nadu 641014.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AddressSection;
