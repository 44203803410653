import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import config from "../utils/config";
import aboutPageQuestionsS3ImagePaths from '../imagePaths/verticalSliderImagePaths';
import VerticalSlide from "./verticalSlide";
import VerticalSlideBackup from "./verticalSlideBackup";


const Aboutpage2 = () => {
  return (

    <>
      <div className="row pd-top-120">

        <div
          className="col-xl-7 col-lg-6"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-duration="1500"
        >
          <div className="section-title-about2 mb-0 mt-4 mt-lg-0">
            {/* <h2
className="title"
style={{ marginTop: "10%", marginBottom: "5%" }}
>
Our Mission
</h2>
<p className="content-about-page" style={{ color: "#242424" }}>
Our mission is to empower businesses to thrive in the digital age by delivering cutting-edge
software solutions that streamline processes, enhance customer experiences,
and unlock new opportunities. We believe that technology should be a catalyst for
positive transformation, and we're committed to making that a reality for every client we work with.
Our diverse skill set enables us to tackle complex challenges and turn them into innovative solutions
that drive real business impact.
</p> */}
            {/*  <ul className="content-about-page" style={{ color: "#242424", fontSize: "25px" }}>
<li>When you think of Fuzionest, think innovation with heart.</li>
<li>We’re passionate problem-solvers who see each project as a gateway to endless opportunities.</li>
<li>Not just for us but for our clients and their communities.</li>
</ul> */}
            <img
              className="about-tidel-image"
              src={`${config.baseUrl}/assets/img/Aboutpage/about_us_tidel_park.png`}
              alt="Our Mission"
            />
          </div>
          <div className="accordion accordion-inner " id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <div className="accordin-button">

                </div>
              </h2>
              {/* <div>
<p
  className="content-about-page"
  style={{ marginTop: "5%", color: "#242424" }}
>
  Your success is our success. We take a client-centric
  approach, focusing on understanding your unique goals,
  challenges, and aspirations. From ideation to implementation,
  we collaborate closely with you to ensure that the solutions
  we provide align perfectly with your vision and requirements.
</p>
</div> */}
            </div>
          </div>
        </div>

        <div
          className="col-xl-5 col-lg-6 col-md-8"
          data-aos="fade-left"
          data-aos-delay="100"
          data-aos-duration="1500"
          style={{ paddingLeft: "50px" }}
        >
          <div className="about-thumb-inner pt-lg-3">

            <h3>About us</h3>
            <ul className="content-about-page content-about-page-2" style={{ paddingLeft: "40px", color: "#66676d", fontSize: "23px", fontWeight: 300, letterSpacing: "1px", padding: "10px" }}>
              <li>Established in 2019.</li>
              <li>A dedicated team of 30+ skilled professionals on Application development, UI/UX designers,Server architecture,Devops Engineers.</li>
              <li>We have client base around US,UK, middle East and india</li>
            </ul>

          </div>
        </div>
      </div>

      <div className="row pd-top-120">

        <div
          className="col-xl-5 col-lg-6 col-md-8"
          data-aos="fade-left"
          data-aos-delay="100"
          data-aos-duration="1500"
          style={{ paddingLeft: "50px" }}
        >
          <div className="about-thumb-inner pt-lg-3">

            <h3>Advancements Gained through Experience</h3>
            <ul className="content-about-page content-about-page-2" style={{ paddingLeft: "40px", color: "#66676d", fontSize: "23px", fontWeight: 300, letterSpacing: "1px", padding: "10px" }}>
              <li>Scalable Multi-Tenant Micro-services.</li>
              <li>Accelerated Market Launch with Innovation Development, Approach - 10x faster than traditional way.</li>
              <li>Advanced Chat Assist Systems.</li>
              <li>Generative AI Automation Bots.</li>
              <li>Predictive Analysis and Anomaly Detection Agents.</li>
              <li>Secure and Optimized Cloud Architecture.</li>
            </ul>

          </div>
        </div>

        <div
          className="col-xl-7 col-lg-6"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-duration="1500"
        >
          <div className="section-title-about2 mb-0 mt-4 mt-lg-0">
            {/* <h2
className="title"
style={{ marginTop: "10%", marginBottom: "5%" }}
>
Our Mission
</h2>
<p className="content-about-page" style={{ color: "#242424" }}>
Our mission is to empower businesses to thrive in the digital age by delivering cutting-edge
software solutions that streamline processes, enhance customer experiences,
and unlock new opportunities. We believe that technology should be a catalyst for
positive transformation, and we're committed to making that a reality for every client we work with.
Our diverse skill set enables us to tackle complex challenges and turn them into innovative solutions
that drive real business impact.
</p> */}
            {/*  <ul className="content-about-page" style={{ color: "#242424", fontSize: "25px" }}>
<li>When you think of Fuzionest, think innovation with heart.</li>
<li>We’re passionate problem-solvers who see each project as a gateway to endless opportunities.</li>
<li>Not just for us but for our clients and their communities.</li>
</ul> */}
            <img
              className="about-tidel-image"
              src={`${config.baseUrl}/assets/img/Aboutpage/about_us_figure_it_out.png`}
              alt="Our Mission"
            />
          </div>
          <div className="accordion accordion-inner " id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <div className="accordin-button">

                </div>
              </h2>
              {/* <div>
<p
  className="content-about-page"
  style={{ marginTop: "5%", color: "#242424" }}
>
  Your success is our success. We take a client-centric
  approach, focusing on understanding your unique goals,
  challenges, and aspirations. From ideation to implementation,
  we collaborate closely with you to ensure that the solutions
  we provide align perfectly with your vision and requirements.
</p>
</div> */}
            </div>
          </div>
        </div>
      </div>

      <VerticalSlide />
      {/* <VerticalSlideBackup /> */}

    </>
  );
};

export default Aboutpage2;
