import HeroSection from "../../../components/Fleet/Product/HeroSection";
import RealTimeTracking from "../../../components/Fleet/Product/RealTimeTracking";
import RealTimeVisibility from "../../../components/Fleet/Product/RealTimeVisibility";
import YellowHighlight from "../../../components/Fleet/Product/YellowHighlight";
import DriverScheduling from "../../../components/Fleet/Product/DriverScheduling";
import ActionalbleSection from "../../../components/Fleet/Product/ActionalbleSection";
import OtherFeatures from "../../../components/Fleet/Product/OtherFeatures";
import RealTimeGpsTracking from "../../../components/Fleet/Product/RealTimeGpsTracking";
import SecurityFeatures from "../../../components/Fleet/Product/SecurityFeatures";
import CallToAction from "../../../components/Fleet/Product/CallToAction";
import FooterFive from "../../../components/footerFive";
import NavBar from "../../../components/NavBar";


const MainPage = () => {
  return (
    <>
      <NavBar />
      <HeroSection />
      <RealTimeTracking />
      <YellowHighlight title="Real-Time Tracking & Playback" />
      <RealTimeVisibility />
      <DriverScheduling />
      <YellowHighlight title="Ride Reports & Analytics" />
      <ActionalbleSection />
      <OtherFeatures />
      <RealTimeGpsTracking />
      <YellowHighlight title="Technology & Security" />
      <SecurityFeatures />
      <CallToAction />
      <FooterFive />
    </>
  );
};

export default MainPage;
