import React from "react";

const PrivacyPolicyContent = () => {
  return (
    <div>
      <h2 className="title-rsl">Fuzionest's Privacy Policy:</h2>
      <div className="rsl-pagetwo">
        <h3 className="rsl-heading">1. Introduction</h3>
        <p className="rsl-para">
          Welcome to <b> Fuzionest</b>. We respect your privacy and are
          committed to protecting your personal data.
        </p>
        <h3 className="rsl-heading">2. Information We Collect</h3>
        <ul>
          <li>
            <strong> Personal Data:</strong> Name, email address, phone number.
          </li>
          <li>
            <strong> Usage Data: </strong> IP address, browser type, pages
            visited, time spent on pages, and other diagnostic data.
          </li>
        </ul>

        <h2 className="rsl-heading" style={{ fontSize: "20px" }}>
          3. How We Use Your Information
        </h2>
        <ul>
          <li>
            <strong>To Provide Services:</strong> To deliver the services you
            request and maintain our site.
          </li>
          <li>
            <strong>To Communicate:</strong> To respond to your inquiries, and
            provide updates and marketing materials.
          </li>
          <li>
            <strong>To Improve Services:</strong> To analyze usage data and
            improve our services.
          </li>
        </ul>
        <h2 className="rsl-heading" style={{ fontSize: "20px" }}>
          4. Data Sharing and Disclosure
        </h2>
        <ul>
          <li>
            <strong>Third-Party Service Providers:</strong> We may share data
            with service providers to help operate our business and site.
          </li>

          <li>
            <strong>Legal Requirements:</strong> We may disclose your data to
            comply with legal obligations or protect our rights.
          </li>
        </ul>
        <h2 className="rsl-heading" style={{ fontSize: "20px" }}>
          5. Data Security
        </h2>
        <p className="rsl-para">
          We use industry-standard security measures to protect your data but
          cannot guarantee absolute security.
        </p>
        <h2 className="rsl-heading" style={{ fontSize: "20px" }}>
          6. Your Data Rights
        </h2>
        <ul>
          <li>
            <strong>Access and Update:</strong>You can access and update your
            personal information.
          </li>
          <li>
            <strong>Deletion:</strong> You can request the deletion of your
            personal data.
          </li>
          <li>
            <strong>Objection:</strong> You can object to the processing of your
            data under certain conditions.
          </li>
        </ul>
        <h2 className="rsl-heading" style={{ fontSize: "20px" }}>
          7. Cookies
        </h2>
        <p className="rsl-para">
          We use cookies to enhance your experience on our site. You can control
          cookies through your browser settings.
        </p>
        <h2 className="rsl-heading" style={{ fontSize: "20px" }}>
          8. Third-Party Links
        </h2>
        <p className="rsl-para">
          Our site may contain links to other sites. We are not responsible for
          the privacy practices of these sites.
        </p>
        <h2 className="rsl-heading" style={{ fontSize: "20px" }}>
          9. Changes to This Policy
        </h2>
        <p className="rsl-para">
          We may update this policy from time to time. We encourage you to
          review it periodically.
        </p>
        <h2 className="rsl-heading" style={{ fontSize: "20px" }}>
          10. Contact Us
        </h2>
        <p className="rsl-para">
          For any questions about this policy or your data, contact us at &nbsp;
          <a style={{ color: "#1967D2" }} href="mailto:contact@fuzionest.com">
            contact@fuzionest.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyContent;
