import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
const Rslpagethree = () => {
  return (
    <div>
      <div className="container">
        <div
          className="row pd-top-100"
          style={{ padding: "0 5%", paddingTop: "4%" }}
        >
          <div
            className="col-xl-5 col-lg-6 col-md-8"
            data-aos="fade-right"
            data-aos-delay="100"
            data-aos-duration="1500"
          >
            <h4
              className="rsl-result"
              style={{
                color: "#202021 ",
                paddingLeft: " 5%",
                fontSize: "30px",
              }}
            >
              The Result
            </h4>

            <div className="about-thumb-inner pt-lg-3">
              <img
                className="main-img rsl-mainimg"
                src="assets/img/rsl/14.svg"
                alt="Features"
                style={{ paddingTop: "5%" }}
              />
            </div>
          </div>
          <div
            className="col-xl-7 col-lg-6"
            data-aos="fade-left"
            data-aos-delay="100"
            data-aos-duration="1500"
          >
            <div className="section-title1 mb-0 mt-4 mt-lg-0">
              <h5
                className="title-rsl-ft"
                style={{ marginTop: "20%", color: "#202021 " }}
              >
                Features:
              </h5>
              <h6
                style={{
                  lineHeight: "300%",
                  color: "#202021 ",
                  fontWeight: "600",
                }}
              >
                Luxury Made Easy:
              </h6>
              <p className="content rsl-para" style={{ color: "#455065" }}>
                Effortlessly book lavish limos through our app, redefining
                opulent travel with simplicity.{" "}
              </p>

              <h6
                style={{
                  lineHeight: "300%",
                  color: "#202021 ",
                  fontWeight: "600",
                }}
              >
                Elite Assurance:
              </h6>
              <p className="rsl-para" style={{ color: "#455065" }}>
                Expect impeccable rides from skilled chauffeurs, ensuring a VIP
                experience every time
              </p>
              <h6
                style={{
                  lineHeight: "300%",
                  color: "#202021 ",
                  fontWeight: "600",
                }}
              >
                Your Way, Anytime:
              </h6>
              <p className="rsl-para" style={{ color: "#455065" }}>
                Choose from self, guest, future, or package bookings, catering
                to all your travel needs.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div
          className="row pd-top-120"
          style={{ padding: "0 5%", marginTop: " 7%" }}
        >
          <div
            className="col-xl-5 col-lg-6 col-md-8 order-lg-last"
            data-aos="fade-left"
            data-aos-delay="100"
            data-aos-duration="1500"
          >
            <div className="about-thumb-inner pt-lg-3">
              <img
                className="main-img"
                src="assets/img/rsl/15.svg"
                alt="Cross Platform"
                style={{ marginTop: "9%" }}
              />
            </div>
          </div>
          <div
            className="col-xl-7 col-lg-6"
            data-aos="fade-right"
            data-aos-delay="100"
            data-aos-duration="1500"
          >
            <div className="section-title-rsl3 mb-0 mt-4 mt-lg-0">
              <h5 style={{ color: "#202021 ", fontSize: "30px" }}>
                The application
              </h5>
              <h6
                style={{
                  color: "#202021 ",
                  lineHeight: "300%",
                  fontWeight: "600",
                }}
              >
                Cross Platform Convenience:
              </h6>
              <p className="rsl-para" style={{ color: "#455065" }}>
                Elevate your ride experience with our app, available on Android
                and iOS devices.
              </p>
              <h6
                style={{
                  color: "#202021 ",
                  lineHeight: "300%",
                  fontWeight: "600",
                }}
              >
                Smart Saving, Personalized:
              </h6>
              <p className="rsl-para" style={{ color: "#455065" }}>
                Enjoy battery-friendly app usage, tailor-made offers, and
                location-based promotions.
              </p>
              <h6
                style={{
                  color: "#202021 ",
                  lineHeight: "300%",
                  fontWeight: "600",
                }}
              >
                Real Time Ease:
              </h6>
              <p className="rsl-para" style={{ color: "#455065" }}>
                Track nearby rides, ETA, and estimated fares effortlessly for
                various vehicle categories.
              </p>
              <h6
                style={{
                  color: "#202021 ",
                  lineHeight: "300%",
                  fontWeight: "600",
                }}
              >
                Seamless Connection:
              </h6>
              <p className="rsl-para" style={{ color: "#455065" }}>
                Stay updated on rides, chat with drivers, and ensure smooth
                server connectivity.
              </p>
              <h6
                style={{
                  color: "#202021 ",
                  lineHeight: "300%",
                  fontWeight: "600",
                }}
              >
                Payment Made Simple:
              </h6>
              <p className="rsl-para" style={{ color: "#455065" }}>
                Choose cash, card, or wallet, and access receipts anytime. Your
                ride, your way
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-3" style={{ padding: "0 31.7%" }}>
        <h3 style={{ color: "#202021 " }}>What our clients say about us:</h3>
        <div
          className="para-border"
          style={{ borderLeft: "3px solid #E0E0E0", paddingLeft: "15px" }}
        >
          <p>
            {" "}
            "Ever since we integrated the taxi booking solution from Fuzionest,
            our operations have seen a remarkable transformation. The app is not
            just user-friendly but also incredibly efficient. Our drivers and
            passengers continually praise its seamless navigation, real-time
            updates, and top-notch security features. This has not only enhanced
            our brand reputation but has also significantly boosted our
            bookings. A heartfelt thank you to the Fuzionest team for their
            unparalleled expertise and commitment to excellence."
          </p>
          {/* <p >Their development process and communication really helped us */}

          {/* save time and get the product out into the market quickly."</p> */}
        </div>
        {/* <h6 style={{fontWeight: '700', marginTop: '3%', marginBottom: '10%'}}>Kellan OConnor - Head of New Product, Epson Ventures</h6> */}

        <Link
          className="btn btn-border-base33"
          // data-aos='fade-right'
          data-aos-delay="500"
          data-aos-duration="1500"
          to="/about"
          //  style={{width: '37%', left: '30%', marginBottom: '10%'}}
        >
          Explore Our Work
        </Link>
      </div>
    </div>
  );
};

export default Rslpagethree;
