import styles from '../Product/ActionalbleSection.module.scss';

const GrowWithoutLimits = () => {
  return (
    <section className={styles.realTimeVisibility}>
      <div className={styles.curvedCard}>
        <div className={styles.textContent}>
          <h2>
            <span className={styles.highlight}>Grow Wit </span>hout Limits  
          </h2>
          <p>
          Our ERP scales with your business, accommodating growth effortlessly.
          </p>
        </div>

        <div className={styles.mapContent}>
          <img src="/assets/img/products/12553962_4965055 2.png" alt="Fleet map and control interface" className={styles.mapImage} />
        </div>
      </div>
    </section>
  );
};

export default GrowWithoutLimits;
