import React from "react";
import config from "../utils/config";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

const WorkProcessOne = () => {
  return (
    <>
      {/*==================== Work Process One start ====================*/}
      <div className="work-process-area">
        {/* <div className="section-title-work1 text-center">
            <h6
              className="sub-titles"
              style={{
                color: "#202021 ",
                marginBottom: "2%",
                marginTop: "5%",
                fontWeight: "600",
              }}
            >
              CORE VALUES
            </h6>
            <h2 className="title" id="workprocess-h2">
              The Standard by Which We Run Our Company
            </h2>
            <p className="work-para" style={{ color: "black" }}>
              At Fuzionest, our values influence every decision we make. Whether
              we’re considering giving employees the freedom to attend
              conferences or building a new feature for our customers, our
              values hold us accountable to be the very best company we can be.
            </p>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img
                    // src="assets/img/Aboutpage/bl1.png"
                    src={`${config.baseUrl}/assets/img/Aboutpage/bl1.png`}
                    alt="About Fuzionest"
                    style={{
                      textAlign: "center",
                      marginLeft: "38%",
                      width: "60px",
                    }}
                  />
                </div>
                <div className="details">
                  <h5 className="mb-3" style={{ textAlign: "center" }}>
                    We Iterate to Success
                  </h5>
                  <p
                    className="content-work-process"
                    style={{
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    Success isn’t a straight line. We
                    <br />
                    acknowledge our mistakes and
                    <br />
                    learn from them to move us
                    <br />
                    closer to where we want to go.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img
                    // src="assets/img/Aboutpage/bl2.png"
                    src={`${config.baseUrl}/assets/img/Aboutpage/bl2.png`}
                    alt="Encourage your Growth"
                    style={{
                      textAlign: "center",
                      marginLeft: "38%",
                      width: "60px",
                    }}
                  />
                </div>
                <div className="details">
                  <h5 className="mb-3" style={{ textAlign: "center" }}>
                    We Encourage Personal Growth
                  </h5>
                  <p
                    className="content-work-process"
                    style={{
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    Behind every great company are
                    <br />
                    great people. We invite
                    <br />
                    employees to become the best
                    <br />
                    version of themselves, both
                    <br />
                    personally and professionally.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img
                    // src="assets/img/Aboutpage/bl3.png"
                    src={`${config.baseUrl}/assets/img/Aboutpage/bl3.png`}
                    alt="Go The Extra Mile"
                    style={{
                      textAlign: "center",
                      marginLeft: "38%",
                      width: "60px",
                    }}
                  />
                </div>
                <div className="details">
                  <h5 className="mb-3" style={{ textAlign: "center" }}>
                    We Go The Extra Mile
                  </h5>
                  <p
                    className="content-work-process"
                    style={{
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    It’s easy to settle for mediocrity.
                    <br />
                    We believe in going above and
                    <br />
                    beyond, whether that’s solving
                    <br />
                    internal challenges or serving
                    <br />
                    our customers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img
                    // src="assets/img/Aboutpage/bl4.png"
                    src={`${config.baseUrl}/assets/img/Aboutpage/bl4.png`}
                    alt="All While Having Fun"
                    style={{
                      textAlign: "center",
                      marginLeft: "38%",
                      width: "60px",
                    }}
                  />
                </div>
                <div className="details">
                  <h5 className="mb-3" style={{ textAlign: "center" }}>
                    All While Having Fun
                  </h5>
                  <p
                    className="content-work-process"
                    style={{
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    Work doesn’t have to be a<br />
                    grind. Nor does it have to be
                    <br />
                    boring. We believe good work
                    <br />
                    comes from having fun not in
                    <br />
                    spite of it.
                  </p>
                </div>
              </div>
            </div>
          </div> */}

        <img
          src={`${config.baseUrl}/assets/img/Aboutpage/backgd.png`}
          alt="thingsImage"
          className="things-image"
        />

        <div className="things" >
          <h2>Together we can do great things</h2>
          <p>
            Want to do work that matters? We’re changing the way how digital
            transformation is approached. Join us today to become part of
            something greater than yourself.
          </p>

          <Link
            className="btn career"
            to="/contact"

          >
            Contact us
          </Link>
        </div>
      </div>

      {/* ==================== Work Process One end ====================*/}
    </>
  );
};

export default WorkProcessOne;
