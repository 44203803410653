import styles from './ActionalbleSection.module.scss';

const ActionalbleSection = () => {
  return (
    <section className={styles.realTimeVisibility}>
      <div className={styles.curvedCard}>
        <div className={styles.textContent}>
          <h2>
            <span className={styles.highlight}>Turn data</span>into actionable insights
          </h2>
          <p>
            Access detailed reports and in-depth analytics to understand ride performance better. From fuel consumption to driver behavior, maintenance schedules to route efficiency, gain insights that help you improve operations and reduce costs.
          </p>
        </div>

        <div className={styles.mapContent}>
          <img src="/assets/img/products/dashboard.png" alt="ride map and control interface" className={styles.mapImage} />
        </div>
      </div>
    </section>
  );
};

export default ActionalbleSection;
