import React, { useState, useEffect } from 'react';
import aboutPageQuestionsS3ImagePaths from '../imagePaths/verticalSliderImagePaths';
import "./verticalSlide.scss";

const questions = [
    { id: 1, question: 'What makes Fuzionest different from other software companies?', image: aboutPageQuestionsS3ImagePaths.q1 },
    { id: 2, question: 'Why should I think of Fuzionest as more than a tech provider?', image: aboutPageQuestionsS3ImagePaths.q2 },
    { id: 3, question: 'How does Fuzionest approach building solutions?', image: aboutPageQuestionsS3ImagePaths.q3 },
    { id: 4, question: 'What kind of partnership can I expect from Fuzionest?', image: aboutPageQuestionsS3ImagePaths.q4 },
    { id: 5, question: 'So, what’s the bigger picture with Fuzionest?', image: aboutPageQuestionsS3ImagePaths.q5 },
];

const VerticalSlideBackup = () => {
    const [selectedId, setSelectedId] = useState(1);
    const [multiplier, setMultiplier] = useState(50); // Default multiplier for desktop

    // Function to update the multiplier based on screen width
    const updateMultiplier = async () => {
        const isMobile = window.innerWidth <= 468;
        setMultiplier(isMobile ? 70 : 50);
    };

    const handleClick = async (id) => {
        setSelectedId(id);
        await updateMultiplier(); // Re-calculate multiplier after selection
    };

    // Initial and resize-based multiplier update
    useEffect(() => {
        updateMultiplier(); // Initial check
        window.addEventListener('resize', updateMultiplier);

        return () => window.removeEventListener('resize', updateMultiplier);
    }, []);

    return (
        <div className="containerVerticalSlide">
            <div className="verticalSlideContainer">
                {/* Left Side - Questions List */}
                <div className="questionList">
                    {/* Pathway - stays static behind the indicator */}
                    <div className="indicatorPathway" />

                    {/* Moving Indicator */}
                    <div
                        className="verticalIndicator"
                        style={{ top: `${selectedId * multiplier - 45}px` }} // Dynamic positioning
                    />

                    {questions.map((item, index) => (
                        <div
                            key={item.id}
                            onClick={async () => await handleClick(item.id)}
                            className={`questionItem ${selectedId === item.id ? 'active' : ''}`}
                        >
                            <span className="questionNumber">{index + 1}.</span>
                            {item.question}
                        </div>
                    ))}
                </div>

                {/* Right Side - Image Display */}
                <div className="contentDisplay">
                    {questions.map((item) =>
                        item.id === selectedId ? (
                            <div key={item.id}>
                                <img
                                    src={item.image}
                                    alt={item.question}
                                    className={`imageDisplay ${selectedId === item.id ? 'imageDisplayVisible' : ''}`}
                                />
                            </div>
                        ) : null
                    )}
                </div>
            </div>
        </div>
    );
};

export default VerticalSlideBackup;
