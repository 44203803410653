import styles from '../Product/ActionalbleSection.module.scss';

const ActionalbleSection = () => {
  return (
    <section className={styles.realTimeVisibility}>
      <div className={styles.curvedCard}>
        <div className={styles.textContent}>
          <h2>
            <span className={styles.highlight}>Connect  </span>Seamlessly
          </h2>
          <p>
            Integrate effortlessly with multiple channels to streamline your accounting processes.
          </p>
        </div>

        <div className={styles.mapContent}>
          <img src="/assets/img/products/Frame1.png" alt="Fleet map and control interface" className={styles.mapImage} />
        </div>
      </div>
    </section>
  );
};

export default ActionalbleSection;
