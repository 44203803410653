import React, { useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the styles

function App() {
  const [email, setEmail] = useState("");
  const emailInputRef = useRef(null);

  const apiPostUrl = "https://api.fuzionest.com/subscribe"; // Define the API POST URL

  const handleSubscribe = async () => {
    const emailValue = emailInputRef.current.value;
    if (!emailValue) {
      toast.error("Please enter an email address.");
    }

    try {
      const response = await fetch(apiPostUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: emailValue }),
      });

      if (response.ok) {
        toast.success("Subscription successful");
        setEmail(""); // Clear the email input
      } else {
        toast.error("Subscription failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="app-container">
      <div className="container" style={{ marginTop: "5%", padding: "0 5%" }}>
        <div className="row">
          <div className="col-md-4">
            <h4 style={{ color: "#202021 ", marginBottom: "10%" }}>
              Get our best content in your inbox
            </h4>
            <p>
              All the tips, stories, and resources you could ever need or want
              straight to your email!
            </p>
          </div>
          <div className="col-md-6" id="blogpage3-col2">
            <div className="input-group mb-1">
              <input
                type="text"
                className="form-control"
                placeholder="Email Address*"
                id="blogpagethree"
                ref={emailInputRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-blogpagethree"
                  type="button"
                  onClick={handleSubscribe}
                >
                  Subscribe
                </button>
              </div>
            </div>
            <p style={{ marginTop: "5%" }}>
              Your privacy matters! Help Scout only uses this info to send
              content and updates. You may unsubscribe anytime. View our{" "}
              <a
                href="/privacy-policy"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                privacy policy
              </a>{" "}
              for more.
            </p>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000} // Adjust the autoClose time as needed
      />
    </div>
  );
}

export default App;
