import React from "react";
import "./Footer.css"; // External CSS for styling
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footers className="footers">
      <div
        className="footers-content"
        style={{ paddingTop: "50px" }}
      >
        {/* Fuzionest Branding */}
        <div className="footers-section">
          <h3>Fuzionest</h3>
          <p>Making Business Resilient</p>
          <Link to='/contact'>
            <button className="footers-button">Connect with us</button>
          </Link>
        </div>

        {/* What We Do Section */}
        {/*  <div className="footers-section">
          <h3>What we do</h3>
          <ul>
            <li>
              <a href="/promote">Promote</a>
            </li>
            <li>
              <a href="/innovate">Innovate</a>
            </li>
            <li>
              <a href="/empower">Empower</a>
            </li>
            <li>
              <a href="/navigate">Navigate</a>
            </li>
            <li>
              <a href="/transform">Transform</a>
            </li>
          </ul>
        </div> */}

        {/* Tailored Solutions */}
        <div className="footers-section">
          <h3>Tailored Solutions</h3>
          <ul>
            <li>
              <a href="/products/ridex">RideX - Ride Management ERP</a>
            </li>
            <li>
              <a /* href="/delixpress" */>DeliXpress - On demand delivery ERP</a>
            </li>
            <li>
              <a /* href="/xpertnow" */>XpertNow - Service booking ERP</a>
            </li>
            <li>
              <a /* href="/xcart" */>X-Cart - Commerce ERP</a>
            </li>
            <li>
              <a href="/products/fuzion-books">Fuzion Books - Accounting ERP</a>
            </li>
          </ul>
        </div>

        {/* Industry */}
        <div className="footers-section">
          <h3>Industry</h3>
          <ul>
            <li>
              <a /* href="/healthcare" */>Healthcare</a>
            </li>
            <li>
              <a /* href="/retail" */>Retail</a>
            </li>
            <li>
              <a /* href="/realestate" */>Real estate</a>
            </li>
            <li>
              <a /* href="/manufacturing" */>Manufacturing</a>
            </li>
            <li>
              <a /* href="/education" */>Education & eLearning</a>
            </li>
            <li>
              <a /* href="/fintech" */>Fintech</a>
            </li>
            <li>
              <a /* href="/logistics" */>Logistics</a>
            </li>
            <li>
              <a /* href="/travel" */>Travel & Hospitality</a>
            </li>
            <li>
              <a /* href="/entertainment" */>Entertainment</a>
            </li>
          </ul>
        </div>

        {/* Who We Are Section */}
        <div className="footers-section">
          <h3>Who we are</h3>
          <ul>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a /* href="/process" */>Process</a>
            </li>
            <li>
              <a href="/technology">Technologies</a>
            </li>
            <li>
              <a href="/about">Life@Fuzionest</a>
            </li>
            <li>
              <a /* href="/education-elearning" */>Educational & e-Learning</a>
            </li>
            <li>
              <a href="/contact">Careers</a>
            </li>
            <li>
              <a href="/blogs">Blog</a>
            </li>
            <li>
              <a /* href="/engagement-model" */>Engagement Model</a>
            </li>
          </ul>
        </div>

        {/* Contact Us */}
        <div className="footers-section">
          <h3>Contact Us</h3>
          <p>+91 8438762921</p>
          <p>
            <a href="mailto:contact@fuzionest.com">contact@fuzionest.com</a>
          </p>
        </div>
      </div>

      <div className="footers-bottom">
        <p>
          Copyright © 2024 Fuzionest, LLC |{" "}
          <a href="/privacy-policy">Privacy Policy</a> |{" "}
          <a >Website Terms</a> |{" "}
          <a >Accessibility</a>
        </p>
        <div className="social-icons">
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>
        </div>
      </div>
    </footers>
  );
};

export default Footer;
