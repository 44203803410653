import React from "react";

const Rslpagetwo = () => {
  return (
    <div>
      <h2 className="title-rsl">Developing Mobile Apps to RSL</h2>
      <div className="rsl-pagetwo">
        <h6 className="rsl-heading">The Client</h6>
        <p className="rsl-para">
          Royal Smart Limousine LLC (formerly known as Royal Luxury Transport
          LLC) is a member of the Rashid Al Jabri Group of Companies. ‘The Smart
          Experience’ in the spirit of Dubai’s vision, optimism, commitment, and
          world-class development.
        </p>
        <p className="rsl-para">
          The company has become the most innovative luxury transport service in
          the industry constantly updating its operations with the latest
          technologies, software, computers, vehicles and not forgetting its
          fully trained, dedicated employees assuring clients of a Smart
          Experience throughout.
        </p>

        <img className="animate-img-rsl" src="assets/img/rsl/7.svg" alt="Objectives" />

        <h6 className="rsl-heading" style={{ paddingTop: "5%" }}>
          Objectives
        </h6>
        <p className="rsl-para">
          To develop a user-friendly taxi booking mobile app that provides
          efficient, real-time, and reliable cab services to users, ensuring
          seamless navigation, accurate fare estimation, and secure payment
          options, ultimately enhancing the overall transportation experience
          for both drivers and passengers.
        </p>

        <h6 className="rsl-heading" style={{ paddingTop: "3%" }}>
          The Solution
        </h6>
        <p className="rsl-para">
          At Fuzionest, we crafted a state-of-the-art taxi booking app,
          emphasizing user experience. Through real-time tracking, precise fare
          calculations, and robust security measures, we've revolutionized the
          commute for drivers and passengers alike, ensuring convenience and
          trust in every ride.
        </p>
      </div>
    </div>
  );
};

export default Rslpagetwo;
