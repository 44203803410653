import React from "react";
import { Link } from "react-router-dom";
import config from "../utils/config";

const StartegicFuzionContent = () => {
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div className="bg-cover pd-top-90">
        <div className="">
          <div className="d-flex justify-content-center position-relative">
            <div className="eclipse"></div>
            <div className="shadow-box">
              <div className="dots">
                <img
                  // src="assets/img/strategic-fuzion/dots.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                  alt="handshake"
                />
              </div>
              <div className="decorate">
                <img
                  // src="assets/img/strategic-fuzion/decorate-1.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-1.png`}
                  alt="handshake"
                />
              </div>
              <img
                className="handshake"
                // src="assets/img/strategic-fuzion/handshake.png"
                src={`${config.baseUrl}/assets/img/strategic-fuzion/handshake.png`}
                alt="handshake"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row g-2 pd-top-10 px-2">
            <div
              className="col-xl-5 col-lg-5 col-md-12 order-lg-last order-md-first d-flex justify-content-center align-items-center"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="mb-0 mt-4 mt-lg-0 section-content">
                <h2 style={{ fontWeight: "600" }}>Deep Industry Knowledge</h2>
                <p className="">
                  <b>Leverage Our Expertise: </b> Our consultants bring
                  extensive industry knowledge and experience from various
                  sectors, providing you with insights that are both relevant
                  and actionable. Benefit from our deep understanding of your
                  industry to drive strategic decision-making.
                </p>
              </div>
            </div>
            <div
              className="col-xl-7 col-lg-7 col-md-12"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="pt-lg-3">
                <img
                  className="main-img"
                  // src="assets/img/strategic-fuzion/industryKnowledge.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/industryKnowledge.png`}
                  alt="Industry Knowledge"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container content-height">
          <div className="row g-2 pd-top-10 position-relative px-2">
            {/* <!-- content --> */}
            <div
              className="col-xl-4 col-lg-4 col-md-12 d-flex justify-content-center align-items-center order-md-first"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-content">
                <h2 style={{ fontWeight: "600" }}>Customized Strategies</h2>
                <p className="text-justify">
                  <b>Tailored to Your Needs:</b> We understand that every
                  business is unique. Our strategies are customized to align
                  with your specific goals and challenges, ensuring you receive
                  a plan that's perfectly suited to your needs.
                </p>
              </div>
            </div>

            {/* <!-- img --> */}
            <div
              className="col-xl-8 col-lg-8 col-md-12 order-md-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0 d-flex justify-content-center justify-content-lg-end">
                <div className="shadow-box-2">
                  <div className="dots">
                    <img
                      // src="assets/img/strategic-fuzion/dots.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                      alt="handshake"
                    />
                  </div>
                  <img
                    className="main-img"
                    src="assets/img/strategic-fuzion/customizedStrategy.png"
                    alt="customized Strategy"
                  />
                  <div className="left-arrow">
                    <img
                      src="assets/img/strategic-fuzion/left-arrow.png"
                      alt="left-arrow"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="work order-md-last">
              <img src="assets/img/strategic-fuzion/work.png" alt="work" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row pd-top-10 px-2">
            <div
              className="col-xl-5 col-lg-5 col-md-12 order-lg-last d-flex justify-content-center align-items-center position-relative"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0 section-content">
                <h2 style={{ fontWeight: "600" }}>Data-Driven Insights</h2>
                <p>
                  <b>Make Informed Decisions: </b> Our approach is rooted in
                  rigorous analysis and data-driven insights. Ensure your
                  strategies are backed by solid evidence, helping you make
                  well-informed decisions that lead to success.
                </p>
              </div>
              <div className="star">
                <img
                  src="assets/img/strategic-fuzion/star.png"
                  alt="handshake"
                />
              </div>
            </div>
            <div
              className="col-xl-7 col-lg-7 col-md-12 d-md-flex justify-content-center justify-content-lg-start"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="shadow-box-2">
                <div className="dots">
                  <img
                    // src="assets/img/strategic-fuzion/dots.png"
                    src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                    alt="dots"
                  />
                </div>
                <div className="decorate">
                  <img
                    // src="assets/img/strategic-fuzion/decorate-2.png"
                    src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-2.png`}
                    alt="handshake"
                  />
                </div>
                <img
                  className="main-img"
                  // src="assets/img/strategic-fuzion/dataDriven.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/dataDriven.png`}
                  alt="data Driven"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row g-2 pd-top-10 px-2">
            {/* <!-- content --> */}
            <div
              className="col-xl-4 col-lg-4 col-md-12 d-flex justify-content-center align-items-center order-md-first"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-content">
                <h2 style={{ fontWeight: "600" }}>Implementation Support</h2>
                <p className="text-justify">
                  <b>Seamless Execution: </b> We don't just create strategies;
                  we help you put them into action. Our implementation support
                  ensures seamless execution, providing guidance and support
                  every step of the way.
                </p>
              </div>
            </div>

            {/* <!-- img --> */}
            <div
              className="col-xl-8 col-lg-8 col-md-12 order-md-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0 d-flex justify-content-center justify-content-lg-end">
                <div className="shadow-box-2">
                  <div className="dots">
                    <img
                      // src="assets/img/strategic-fuzion/dots.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                      alt="handshake"
                    />
                  </div>
                  <div className="left-arrow-2">
                    <img
                      // src="assets/img/strategic-fuzion/left-arrow-2.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/left-arrow-2.png`}
                      alt="left-arrow"
                    />
                  </div>
                  <img
                    className="main-img"
                    // src="assets/img/strategic-fuzion/implementationSupport.png"
                    src={`${config.baseUrl}/assets/img/strategic-fuzion/implementationSupport.png`}
                    alt="Implementation Support"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Future Oriented */}

        <div className="section-content position-relative py-4 my-4">
          <img
            className="main-img"
            // src="assets/img/strategic-fuzion/decorate-group.png"
            src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-group.png`}
            alt="Future Oriented"
            style={{ width: "100%" }}
          />
          <h2 className="float-content" style={{ fontWeight: "600" }}>
            Future-Oriented Thinking
          </h2>
        </div>

        <div className="container">
          <div className="row g-2 pd-top-10 px-2">
            <div
              className="col-xl-5 col-lg-6 col-md-12 order-lg-last d-flex justify-content-center position-relative"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0 section-content">
                <h2 style={{ fontWeight: "600" }}>Deep Industry Knowledge</h2>
                <p className="">
                  <b>Embrace Innovation: </b> Look beyond the present with our
                  visionary approach. Identify opportunities and trends that
                  will shape the future of your industry, helping you stay ahead
                  of the curve.
                </p>
              </div>
              <div className="star-2">
                <img
                  // src="assets/img/strategic-fuzion/star-2.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/star-2.png`}
                  alt="handshake"
                />
              </div>
              <div className="left-arrow-3">
                <img
                  // src="assets/img/strategic-fuzion/left-arrow-3.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/left-arrow-3.png`}
                  alt="left-arrow"
                />
              </div>
            </div>
            <div
              className="col-xl-7 col-lg-6 col-md-12 d-flex justify-content-center justify-content-lg-start"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="shadow-box-2">
                <div className="dots">
                  <img
                    // src="assets/img/strategic-fuzion/dots.png"
                    src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                    alt="dots"
                  />
                </div>
                <img
                  className="main-img"
                  // src="assets/img/strategic-fuzion/embraceInnovation.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/embraceInnovation.png`}
                  alt="Embrace Innovation"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row g-2 pd-top-10 innovative-section px-2">
            <div
              className="col-xl-7 col-lg-6 col-md-12 order-lg-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title-faq mb-0 mt-4 mt-lg-0 d-flex justify-content-center justify-content-lg-end">
                <div className="shadow-box-2">
                  <div className="dots">
                    <img
                      // src="assets/img/strategic-fuzion/dots.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/dots.png`}
                      alt="handshake"
                    />
                  </div>
                  <div className="star-1">
                    <img
                      // src="assets/img/strategic-fuzion/star.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/star.png`}
                      alt="handshake"
                    />
                  </div>
                  <div className="decorate-right">
                    <img
                      // src="assets/img/strategic-fuzion/decorate-3.png"
                      src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-3.png`}
                      alt="left-arrow"
                    />
                  </div>
                  <img
                    className="main-img"
                    // src="assets/img/strategic-fuzion/innovativeSolution.png"
                    src={`${config.baseUrl}/assets/img/strategic-fuzion/innovativeSolution.png`}
                    alt="Innovative Solution"
                  />
                </div>
              </div>
            </div>

            <div
              className="col-xl-5 col-lg-6 col-md-12 d-flex row g-2 justify-content-center align-items-center"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-content">
                <h2 style={{ fontWeight: "600" }}>Implementation Support</h2>
                <p className="text-justify">
                  <b>Think Outside the Box: </b> Incorporate innovative
                  solutions into your strategy. We challenge conventional
                  thinking, encouraging creative and out-of-the-box ideas that
                  drive breakthrough results.
                </p>
              </div>
              <div className="custom-border"></div>
              <div className="section-content">
                <h2 style={{ fontWeight: "600" }}>Long-Term Planning</h2>
                <p className="text-justify">
                  <b>Focus on Sustainable Growth: </b> Our vision extends beyond
                  short-term gains. Develop strategies that ensure sustainable
                  growth and lasting impact, focusing on long-term success.
                </p>
              </div>
              <div className="custom-border"></div>
              <div className="section-content">
                <h2 style={{ fontWeight: "600" }}>Goal Alignment</h2>
                <p className="text-justify">
                  <b>Cohesive Strategy: </b> Align your strategies with your
                  overarching vision. Ensure every step you take is in line with
                  your long-term goals, creating a cohesive and focused approach
                  to success.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            className="d-flex justify-content-center position-relative"
            style={{ paddingBottom: "100px" }}
          >
            <img
              className="main-img"
              // src="assets/img/strategic-fuzion/vector-bg.png"
              src={`${config.baseUrl}/assets/img/strategic-fuzion/vector-bg.png`}
              alt="Vector-bg"
            />
            <p className="custom-float" style={{ fontWeight: "600" }}>
              <em>Wait! There is more!</em>
            </p>
            <div className="left-arrow-4">
              <img
                // src="assets/img/strategic-fuzion/left-arrow-4.png".
                src={`${config.baseUrl}/assets/img/strategic-fuzion/left-arrow-4.png`}
                alt="left-arrow"
              />
            </div>
          </div>
        </div>

        {/* All other features */}

        <div className="features-section">
          <div className="container">
            <div className="pd-top-10" style={{ paddingTop: "10%" }}>
              <h2 className="feature-content">All other features</h2>
              <div className="row g-2 px-2">
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        // src="assets/img/strategic-fuzion/tiny-star.png"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>Holistic Approach</h2>
                      <p className="text-justify">
                        <b>Comprehensive Solutions: </b> Achieve success with
                        our holistic approach. By integrating consulting
                        expertise with visionary thinking, we provide
                        comprehensive solutions that address every aspect of
                        your business.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        // src="assets/img/strategic-fuzion/tiny-star.png"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>Measurable Outcomes</h2>
                      <p className="text-justify">
                        <b>Track Your Progress: </b> Set clear, quantifiable
                        objectives and use key performance indicators (KPIs) to
                        monitor your success. Track your progress and make
                        adjustments as needed to ensure you stay on the path to
                        success.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        // src="assets/img/strategic-fuzion/tiny-star.png"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>
                        Continuous Improvement
                      </h2>
                      <p className="text-justify">
                        <b>Stay Ahead:</b> Our commitment to excellence means we
                        constantly refine and enhance our strategies. Benefit
                        from the latest insights and best practices, ensuring
                        you always stay ahead.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 d-flex justify-content-center align-items-center"
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <div className="shadow-box-3">
                    <div className="d-flex justify-content-end">
                      <img
                        className="main-img"
                        // src="assets/img/strategic-fuzion/tiny-star.png"
                        src={`${config.baseUrl}/assets/img/strategic-fuzion/tiny-star.png`}
                        alt="Vector-bg"
                      />
                    </div>
                    <div className="section-content">
                      <h2 style={{ fontWeight: "600" }}>
                        Collaborative Partnership
                      </h2>
                      <p className="text-justify">
                        <b>Foster Collaboration: </b> Work closely with us in a
                        collaborative environment where your insights and
                        feedback shape our approach. Drive mutual success
                        through a true partnership.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="footer-content">
            <div className="position-relative">
              <h2 className="my-4">
                Ready to
                <span style={{ color: "#357C83" }}>
                  Transform Your Business?
                </span>
              </h2>
              <div className="decorate-2">
                <img
                  // src="assets/img/strategic-fuzion/decorate-4.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-4.png`}
                  alt="handshake"
                />
              </div>
              <div className="decorate-right-2">
                <img
                  // src="assets/img/strategic-fuzion/decorate-5.png"
                  src={`${config.baseUrl}/assets/img/strategic-fuzion/decorate-5.png`}
                  alt="handshake"
                />
              </div>
            </div>
            <Link to="/contact">
              <button className="btn btn-base border-radius-0">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default StartegicFuzionContent;
