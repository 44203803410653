import styles from '../Product/RealTimeVisibility.module.scss';

const DriverScheduling = () => {
  return (
    <section className={styles.realTimeVisibility}>
      <div className={styles.curvedCard}>
        <div className={styles.textContent}>
          <h2>
          <span className={styles.highlight}>Your Data, </span>Our Priority
          </h2>
          <p>
          Rest easy knowing your financial data is protected with top-notch security measures.
          </p>
        </div>

        <div className={styles.mapContent}>
          <img src="/assets/img/products/data.png" alt="Fleet map and control interface" className={styles.mapImage} />
        </div>
      </div>
    </section>
  );
};

export default DriverScheduling;
