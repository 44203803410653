import styles from './DriverScheduling.module.scss';

const DriverScheduling = () => {
  return (
    <section className={styles.driverSchedulingSection}>
      <div className={styles.contentWrapper}>
        <h2>
          Driver Scheduling <br/>
        </h2>
        <h3>
         & Dispatching
        </h3>

        <div className={styles.imageWrapper}>
          <img
            src="/assets/img/products/fleet_map_1.png"
            alt="Driver Scheduling Interface"
            className={styles.mainImage}
          />
          <img
            src="/assets/img/products/fleet_map_2.png"
            alt="Dispatching Panel"
            className={styles.overlayImage}
          />
        </div>

        <p className={styles.description}>
          <strong>Streamline driver management</strong> by easily scheduling drivers, assigning tasks, and dispatching them to the right location with just a few clicks. Avoid conflicts, reduce downtime, and ensure timely deliveries with optimized route planning and real-time driver updates.
        </p>
      </div>
    </section>
  );
};

export default DriverScheduling;
