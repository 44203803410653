import styles from "./RealTimeGpsTracking.module.scss";

const RealTimeGpsTracking = () => {
  return (
    <section className={styles.realTimeGpsTrackingSection}>
      <div className={styles.textContent}>
        <div className={styles.innerContent}>
          <h2>Real-Time GPS Tracking</h2>
          <p>
            Track the live location of all vehicles in your ride with real-time
            updates.
          </p>
        </div>
      </div>

      <div className={styles.visualContent}>
        <img
          src="/assets/img/products/rightCircle.png"
          alt="Decorative Circle"
          className={styles.circleBackground}
        />
      </div>
    </section>
  );
};

export default RealTimeGpsTracking;
