import styles from './OtherFeatures.module.scss';

const OtherFeatures = () => {
  return (
    <section className={styles.otherFeaturesSection}>
      <div className={styles.animatedMessage}>
        <img
          src="/assets/img/products/animMsg.png"
          alt="Animated CEO Message"
          className={styles.messageImage}
        />
      </div>

      <div className={styles.featuresWrapper}>
        <h2>Other Features</h2>

        <div className={styles.featuresContainer}>
          {/* Feature 1 */}
          <div className={styles.featureCard}>
            <img
              src="/assets/img/products/others1.png"
              alt="Simplify Your Ride Bookings"
              className={styles.featureImage}
            />
            <div className={styles.featureText}>
              <h3>Simplify Your Ride Bookings - Messenger based <span>Ride</span> Booking</h3>
              <p>
                Leverage the power of WhatsApp for a seamless booking experience. Easily book your ride through our integrated WhatsApp feature, making it quicker and more convenient than ever.
              </p>
              <button className={styles.viewMoreBtn} style={{ marginTop: "62px" }}>View More</button>
            </div>
          </div>

          {/* Feature 2 */}
          <div className={styles.featureCard}>
            <img
              src="/assets/img/products/others2.png"
              alt="Agents for Your Regular Tasks"
              className={styles.featureImage}
            />
            <div className={styles.featureText}>
              <h3>Agents for Your Regular Tasks</h3>
              <p>
                Introducing Agent AI, designed to revolutionize how you manage your daily tasks. Seamlessly automate vehicle dispatching, driver assignments, and streamline your entire workflow. Our AI-powered agent takes over routine tasks, enhancing or even replacing manual processes. For example, our agent is trained to automatically read emails, identify booking requests, extract relevant details.

              </p>
              <button className={styles.viewMoreBtn}>View More</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OtherFeatures;
