import React from "react";
import Footer from "../components/Footer";
import Aboutpage1 from "../components/Aboutpage1";
import Aboutpage2 from "../components/Aboutpage2";
import Aboutpage4 from "../components/Aboutpage4";
import NavBar from "../components/NavBar";
import WorkProcessOne from "../components/WorkProcessOne";
import Aboutpage3 from "../components/Aboutpage3";
import Aboutpage5 from "../components/Aboutpage5";
import FooterFive from "../components/footerFive";
import { Helmet } from "react-helmet";
const About = () => {
  const browserTabTitle =
    "About Fuzionest: Innovators in Tech Consulting and Automation Solutions";
  const seoTitle =
    "About Fuzionest: Innovators in Tech Consulting and Automation Solutions";
  const seoDescription =
    "Learn about Fuzionest, a leader in tech consulting and automation solutions. Discover our mission, values, and commitment to driving innovation through advanced technologies like AI. Explore how we empower businesses to achieve their goals with cutting-edge solutions and personalized service.";
  const seoKeywords =
    "About fuzionest, tech consulting company, automation solutions, IT service provider, company mission, company values, tech innovation, ai solutions, business technology, company history, leadership team, fuzionest team.";

  return (
    <>
      <Helmet>
        <title>{browserTabTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "${browserTabTitle}",
              "url": "https://www.fuzionest.com/about",
              "description": "${seoDescription}",
              "mainEntity": {
                "@type": "Organization",
                "name": "Fuzionest",
                "url": "https://www.fuzionest.com",
                "logo": "https://www.fuzionest.com/assets/img/landing/FuzionestLogo.svg",
                "description": "Fuzionest is a leader in tech consulting and automation solutions, specializing in advanced technologies like AI and personalized service.",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "contactType": "Customer Service",
                  "telephone": "+91 84387 62921",
                  "email": "contact@fuzionest.com",
                  "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Coimbatore",
                    "addressRegion": "Tamil Nadu",
                    "postalCode": "641014",
                    "addressCountry": "India",
                    "streetAddress": "Tidel Park, Module No.12 Ground floor Elcot SEZ Coimbatore IT Park Road"
                  }
                }
              }
            }
          `}
        </script>
      </Helmet>
      <NavBar />
      <Aboutpage1 />
      <Aboutpage2 />
      <Aboutpage5 />
      <Aboutpage3 />
      <WorkProcessOne />
      <FooterFive />
    </>
  );
};

export default About;
