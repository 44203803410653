import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ProcessBlogContent from "../components/processAutomation";
import ProcessAutomationBlog from "../components/processAutomationBlog";

const ProcessAutomationBlogPage = () => {
  return (
    <>
      <NavBar />
      <ProcessAutomationBlog />
      <ProcessBlogContent />
      {/* <Rslpagethree /> */}
      <Footer />
    </>
  );
};

export default ProcessAutomationBlogPage;
