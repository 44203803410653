import React from "react";
import NavBar from "../components/NavBar";
import FooterFive from "../components/footerFive";
import Blogpagethree from "../components/Blogpagethree";
import Blogpagefour from "../components/Blogpagefour";
import { Helmet } from "react-helmet";

const BlogDetails = () => {
  const browserTabTitle =
    "Insights, Trends, and Expertise: Explore Our Technology Blog | Fuzionest";
  const seoTitle =
    "Insights, Trends, and Expertise: Explore Our Technology Blog | Fuzionest";
  const seoDescription =
    "Dive into Fuzionest's technology blog for the latest insights, trends, and expert perspectives. Stay informed with our articles on tech consulting, project development, AI automation, and more. Enhance your knowledge and stay ahead in the rapidly evolving tech landscape.";
  const seoKeywords =
    "Technology blog, tech insights, industry trends, expert perspectives, ai technology, project development tips, automations trends, tech consulting articles, digital transformation, business technology updates.";
  return (
    <>
      <Helmet>
        <title>{browserTabTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />

        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Blog",
              "name": "${browserTabTitle}",
              "url": "https://www.fuzionest.com/blog",
              "description": "${seoDescription}",
              "mainEntity": {
                "@type": "Article",
                "headline": "${browserTabTitle}",
                "description": "${seoDescription}",
                "articleBody": "Dive into Fuzionest's technology blog for the latest insights, trends, and expert perspectives. Stay informed with our articles on tech consulting, project development, AI automation, and more.",
                "author": {
                  "@type": "Organization",
                  "name": "Fuzionest"
                },
                "publisher": {
                  "@type": "Organization",
                  "name": "Fuzionest",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.fuzionest.com/assets/img/landing/FuzionestLogo.svg"
                  }
                },
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "https://www.fuzionest.com/blog"
                }
              }
            }
          `}
        </script>
      </Helmet>
      {/* Navigation Bar */}
      <NavBar />
      <Blogpagefour />
      <Blogpagethree />
      <FooterFive />
    </>
  );
};

export default BlogDetails;
